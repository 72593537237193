import React, { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainComponent from "../MainComponent";
import "./ExecutionReadiness.css";
import { url } from "../../store/sagas/API.js";
import { getRandomColor, createImageFromInitials } from "../Utils";
import { Button } from "react-bootstrap";
import UserProfile from "../../assets/images/user-profile.svg";
import Select from "react-select";
import Alert from "../Alert.js";
import {
  getEmployeeData,
  getExecutionReadinessDetails,
  getExistingFunctions,
  postExecutionReadinessDetails,
  postStrategyFunctions,
} from "../../store/actions";
import { resetExceptionError } from "../../store/actions/index.js";
import { useNavigate } from "react-router-dom";
import ProgressBar from "react-customizable-progressbar";
import HelpInfo from "../../utils/helpInfoProviderComponent.js";
import keys from "../../utils/helpInfoKeys.json";
import CommentSection from "../../utils/CommentSection.js";
import Cancel from "../../assets/images/Cancel.svg";
import Login from "../Login.js";
import SideNavHelpInfo from "../../utils/SideNavHelpInfo.js";
import { motion } from "motion/react";

function ExecutionReadiness() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedHelpInfoSidebar = useSelector(
    (state) => state.selectedHelpInfoSidebar
  );
  const [currentDocURL, setCurrentDocURL] = useState("");
  const [itemId, setItemIds] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isAddFunctionsOpen, setIsAddFunctionsOpen] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [emp_data, setEmpData] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [addExisting, setAddExisting] = useState(false);
  const [functionData, setFunctionData] = useState([]);
  const [checkboxStates, setCheckboxStates] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [showRemoveIcon, setShowRemoveIcon] = useState(null);
  const [ClickOnEdit, setClickOnEdit] = useState(false);
  const [deleteFunction, setDeleteFunction] = useState(false);
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [nextButtonClicked, setNextButtonClicked] = useState(false);
  const [progress, setProgress] = useState(0);
  const [EditFunctionCardClick, setEditFunctionCardClick] = useState(false);
  const [assignedTo, setAssignedTo] = useState([]);
  const [editFunctionId, setEditFunctionId] = useState("");
  const selectedPermission = useSelector((state) => state.selectedPermission);
  const getExistingFunctionsData = useSelector(
    (state) => state.getExistingFunctionsData
  );
  const template_url = useSelector((state) => state.template_url);
  const isURLAdded = useSelector((state) => state.isURLAdded);

  const postStrategyFunctionDetails = useSelector(
    (state) => state.postStrategyFunctionDetails
  );
  const ISError = useSelector((state) => state.ISError);
  const fetchpostExecutionReadinessDetails = useSelector(
    (state) => state.fetchpostExecutionReadinessDetails
  );
  const fetchExecutionReadinessFunctionData = useSelector(
    (state) => state.fetchExecutionReadinessFunctionData
  );
  const getEmployeeDetails = useSelector((state) => state.getEmployeeDetails);
  const [functionList, setFunctionList] = useState([]);
  const [irm_score, setIrmScore] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    assigned_to: null,
  });
  useEffect(() => {
    if (fetchExecutionReadinessFunctionData) {
      const score = fetchExecutionReadinessFunctionData.score;
      const irm_score = fetchExecutionReadinessFunctionData.irm_score;
      setProgress(score);
      setIrmScore(irm_score);
    }
  }, [fetchExecutionReadinessFunctionData]);

  useEffect(() => {
    if (fetchExecutionReadinessFunctionData) {
      setFunctionList(fetchExecutionReadinessFunctionData.functions);
    }
  }, [fetchExecutionReadinessFunctionData]);

  const bi_id = localStorage.getItem("bi_id");
  const product_id = localStorage.getItem("product_id");
  useEffect(() => {
    if (bi_id && product_id) {
      dispatch(
        getExecutionReadinessDetails(
          localStorage.getItem("bi_id"),
          localStorage.getItem("product_id")
        )
      );
    }
  }, []);

  useEffect(() => {
    if (isURLAdded) {
      setCurrentDocURL(template_url);
    }
  }, [isURLAdded]);

  useEffect(() => {
    if (getExistingFunctionsData && getExistingFunctionsData.old_fun) {
      setFunctionData(getExistingFunctionsData.old_fun);
    }
  }, [getExistingFunctionsData]);

  useEffect(() => {
    if (
      getEmployeeDetails &&
      getEmployeeDetails.emp_data &&
      getEmployeeDetails.emp_data.length > 0
    ) {
      setEmpData(
        getEmployeeDetails.emp_data.map((obj) => {
          return {
            value: obj.id,
            label: obj.prop_name,
            icon: createImageFromInitials(
              30,
              obj.prop_name,
              getRandomColor(obj.prop_name)
            ),
          };
        })
      );
    }
  }, [getEmployeeDetails]);
  // useEffect(() => {
  //   if (
  //     postStrategyFunctionDetails &&
  //     postStrategyFunctionDetails.response &&
  //     postStrategyFunctionDetails.action === "add"
  //   ) {
  //     if (postStrategyFunctionDetails.stage_type === "function") {
  //       postStrategyFunctionDetails.response.map((item) => {
  //         const newFunction = {
  //           name: item.value,
  //           id: item.key,
  //           assigned_to: item.assigned_to,
  //           icon: "/files/f4.svg",
  //           children: [],
  //         };
  //         setFunctionList([...functionList, newFunction]);
  //       });
  //     }
  //   }
  // }, [postStrategyFunctionDetails]);

  useEffect(() => {
    if (postStrategyFunctionDetails && postStrategyFunctionDetails.response) {
      window.location.reload();
    }
  }, [postStrategyFunctionDetails]);
  const openModal = () => {
    setIsOpen(true);
    dispatch(getEmployeeData(localStorage.getItem("bi_id")));
  };
  const openAlert = () => setIsShow(true);
  const closeModal = () => {
    setIsOpen(false);
    setAddExisting(false);
    setEditFunctionCardClick(false);
    setClickOnEdit(false);
  };
  const closeAddModal = () => {
    setIsAddFunctionsOpen(false);
    setAddExisting(false);
  };
  const handleBack = () => {
    navigate("/strategyreadiness", { replace: true });
  };
  const handleClick = () => {
    setNextButtonClicked(true);
    navigate("/dashboard", { replace: true });
  };
  const customFilter = (option, searchText) => {
    if (option.data.label.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      display: "inline-block",
      border: "1px solid #ccc",
      boxSizing: "border-box",
      paddingLeft: 0,
      borderRadius: "10px",
    }),
    img: {
      height: 40,
    },
  };

  const handleChange = (selectedOption, item) => {
    const updatedList = functionList.map((func) =>
      func.id === item
        ? {
            ...func,
            assigned_to: emp_data.map((emp) => ({
              ...emp,
              is_active: selectedOption.some(
                (user) => user.value === emp.value
              ),
            })),
          }
        : func
    );
    setFunctionList(updatedList);
  };

  const handleOwnerSubmit = () => {
    const requiredAssignedData = functionList.map((funcItem) => ({
      ...funcItem,
      bi_id: localStorage.getItem("bi_id"),
      product_id: localStorage.getItem("product_id"),
      function_name: funcItem.name,
      function_id: funcItem.id,
      is_active: true,
      assigned_to: funcItem.assigned_to.map((item) => ({
        key: item.value,
        is_active: item.is_active,
        label: item.label,
      })),
    }));
    dispatch(postStrategyFunctions(requiredAssignedData, "function", true));
    setIsOpen(false);
    setAddExisting(false);
    setEditFunctionCardClick(false);
  };
  // const handleSubmit = (event) => {
  //   // if (addExisting) {
  //   const requiredAssignedData = emp_data?.map((item) => ({
  //     key: item.value,
  //     is_active: false,
  //   }));
  //   const selectedFunctionsData = selectedLabels.map((label) => ({
  //     bi_id: localStorage.getItem("bi_id"),
  //     product_id: localStorage.getItem("product_id"),
  //     function_name: label.name,
  //     function_id: label.id,
  //     is_active: true,
  //     assigned_to: requiredAssignedData,
  //   }));
  //   const selectedFunctionData = selectedLabels.map((label) => ({
  //     name: label.name,
  //     id: label.id,
  //     is_active: true,
  //     is_master: label.is_master,
  //     assigned_to: requiredAssignedData,
  //   }));
  //   setFunctionList((prevFunctionList) => [
  //     ...prevFunctionList,
  //     ...selectedFunctionData,
  //   ]);
  //   dispatch(postStrategyFunctions(selectedFunctionsData, "function", true));
  //   closeModal();
  //   setAddExisting(false);
  // };

  const handleCheckboxChange = (optionValue) => {
    const newCheckboxStates = {
      ...checkboxStates,
      [optionValue]: !checkboxStates[optionValue],
    };
    setCheckboxStates(newCheckboxStates);
    const selectedLabel = getExistingFunctionsData.old_fun.find(
      (label) => label.id === optionValue
    );
    if (newCheckboxStates[optionValue]) {
      setSelectedLabels([...selectedLabels, selectedLabel]);
    } else {
      setSelectedLabels(
        selectedLabels.filter((label) => label.id !== optionValue)
      );
    }

    const hasCheckedCheckbox = Object.values(newCheckboxStates).some(
      (isChecked) => isChecked
    );
    setIsButtonDisabled(!hasCheckedCheckbox);
  };
  const getColorClass = (progress) => {
    if (progress <= 33) {
      return "red";
    } else if (progress <= 66) {
      return "#ff7400";
    } else {
      return "green";
    }
  };
  const colorClass = getColorClass(irm_score);
  const handleDeleteFunction = (e, itemId) => {
    e.stopPropagation();
    setDeleteFunction(true);
    const latestList =
      functionList &&
      functionList.map((item) =>
        item.id === itemId ? { ...item, is_active: false } : item
      );
    setFunctionList(latestList);
  };
  const handleEditClick = () => {
    setClickOnEdit(true);
  };
  const handleSaveClick = () => {
    setClickOnEdit(false);
    const itemIndex = functionList.findIndex(
      (item) => item.id === editFunctionId
    );
    if (itemIndex !== -1) {
      functionList[itemIndex].assigned_to = assignedTo;
    }
    const data = {
      data: [...functionList],
      bi_id: localStorage.getItem("bi_id"),
      product_id: localStorage.getItem("product_id"),
    };
    dispatch(postExecutionReadinessDetails(data));
  };
  useEffect(() => {
    if (fetchpostExecutionReadinessDetails) {
      window.location.reload();
    }
  }, [fetchpostExecutionReadinessDetails]);
  const handleFunctionCardClick = (function_id) => {
    const selectedFunction = functionList.find(
      (func) => func.id === function_id
    );
    if (
      !selectedFunction.assigned_to ||
      !selectedFunction.assigned_to.some((j) => j.is_active)
    ) {
      setClickOnEdit(true);
      const activeOwner = selectedFunction.assigned_to.find(
        (assignee) => assignee.is_active
      );
      if (activeOwner) {
        activeOwner.icon = createImageFromInitials(
          30,
          activeOwner.label,
          getRandomColor(activeOwner.label)
        );
      }
      setSelectedOption(activeOwner);
      setEditFunctionCardClick(true);
    } else {
      localStorage.setItem("function_id", function_id);
      navigate("/executionreadinessfunction");
    }
  };
  const handleEditFunctionCardClick = (function_id) => {
    setEditFunctionId(function_id);
    const selectedFunction = functionList.find(
      (func) => func.id === function_id
    );
    const activeOwners = selectedFunction.assigned_to.filter(
      (assignee) => assignee.is_active
    );
    if (activeOwners) {
      activeOwners.forEach((owner) => {
        owner.icon = createImageFromInitials(
          30,
          owner.label, // Assuming 'label' contains the initials or name
          getRandomColor(owner.label) // Generate a random color based on the label
        );
      });
    }
    setSelectedOption(activeOwners);
    setEditFunctionCardClick(true);
  };
  useEffect(() => {
    if (EditFunctionCardClick) {
      openModal();
    }
  }, [EditFunctionCardClick]);

  const content = (
    <>
      <motion.div
        initial={{ y: "100%" }}
        animate={{ y: 0 }}
        transition={{ duration: 0.8, ease: "easeInOut" }}
        className="Container"
      >
        <div
          style={{
            display: "flex",
            width: "98%",
            justifyContent: "space-between",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    fontSize: "var(--main-heading)",
                    fontWeight: "var(--heading-font-weight)",
                  }}
                >
                  Execution Readiness
                </div>
                <div
                  style={{
                    height: "30px",
                    width: "30px",
                    marginLeft: "10px",
                    textAlign: "center",
                    color: "white",
                    paddingTop: "2px",
                    backgroundColor: getColorClass(progress),
                    borderRadius: "30px",
                  }}
                >
                  {progress}
                </div>
              </div>
              <div>
                {ClickOnEdit && (
                  <span style={{ fontSize: "var(--menu-font-size)" }}>
                    Click on any Function to edit/update.
                  </span>
                )}
              </div>
            </div>
            <br />
          </div>
          <div style={{ position: "absolute", right: "5%" }}>
            <ProgressBar
              radius={70}
              children={120}
              progress={irm_score}
              steps={100}
              strokeColor={colorClass}
              pointerStrokeColor={colorClass}
              cut={180}
              rotate={180}
              strokeWidth={8}
              trackStrokeWidth={8}
              pointerRadius={8}
              pointerStrokeWidth={5}
            >
              <div className="indicator">
                <div className="indicator-number">{irm_score}</div>
                <div className="indicator-text">XL IRM Score:</div>
              </div>
            </ProgressBar>
          </div>
        </div>
        <div style={{ position: "relative" }}>
          <div className="exe-readiness-card-list">
            {functionList &&
              functionList.length > 0 &&
              functionList.map((functionItem) => (
                <div key={functionItem.id}>
                  {functionItem.is_active && (
                    <div
                      className="card exe-readiness-card"
                      onMouseEnter={() => setShowRemoveIcon(functionItem.id)}
                      onMouseLeave={() => setShowRemoveIcon(null)}
                      onClick={() => {
                        !ClickOnEdit
                          ? handleFunctionCardClick(functionItem.id)
                          : handleEditFunctionCardClick(functionItem.id);
                        setEditFunctionId(functionItem.id);
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div>
                          <img
                            alt="icon"
                            src={url + functionItem.icon}
                            style={{ paddingBottom: "5px", width: "30px" }}
                          />
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            {functionItem.assigned_to &&
                            functionItem.assigned_to.length > 0 &&
                            functionItem.assigned_to.find(
                              (j) => j.is_active
                            ) ? (
                              <img
                                alt="owner"
                                src={createImageFromInitials(
                                  30,
                                  functionItem.assigned_to.find(
                                    (j) => j.is_active
                                  ).label,
                                  getRandomColor(
                                    functionItem.assigned_to.find(
                                      (j) => j.is_active
                                    ).label
                                  )
                                )}
                              />
                            ) : (
                              <>
                                <img alt="" src={UserProfile} />
                              </>
                            )}
                          </div>
                          {functionItem.fun_score && (
                            <div
                              style={{
                                height: "30px",
                                width: "30px",
                                marginLeft: "5px",
                                textAlign: "center",
                                paddingTop: "2px",
                                color: "white",
                                backgroundColor: getColorClass(
                                  functionItem.fun_score[0]
                                ),
                                borderRadius: "30px",
                              }}
                            >
                              {functionItem.fun_score &&
                                functionItem.fun_score[0]}
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        key={functionItem.id}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h6 className="exe-readiness-card-title">
                          <div>{functionItem.name}</div>
                        </h6>
                        {/* <div
                          style={{ display: "flex", alignItems: "center" }}
                          key={functionItem.id}
                        >
                          {showRemoveIcon === functionItem.id &&
                            ClickOnEdit && (
                              <button
                                style={{
                                  color: "red",
                                  marginLeft: 5,
                                  background: "white",
                                  height: 17,
                                  width: 17,
                                  borderRadius: 17,
                                  border: "thin ridge",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                                type="button"
                                onClick={(e) =>
                                  handleDeleteFunction(e, functionItem.id)
                                }
                              >
                                <img src={Cancel} width={20} height={20} />
                              </button>
                            )}
                        </div> */}
                      </div>
                      <div className="exe-readiness-progress-card">
                        <div
                          style={{
                            width: functionItem?.fun_progress
                              ? functionItem.fun_progress + "%"
                              : "0%",
                            maxWidth: "100%",
                            height: "3px",
                            backgroundColor: "#0da14b",
                          }}
                        ></div>
                      </div>
                      <div
                        style={{
                          fontSize: "var(--captions-font-size)",
                          padding: "10px 0px 10px 0px",
                        }}
                      >
                        Required
                      </div>

                      <div className="exe-readiness-children">
                        {functionItem.children &&
                          functionItem.children.length > 0 &&
                          functionItem.children
                            .slice(0, 3)
                            .map((child, index) => (
                              <div
                                key={index}
                                className="exe-readiness-children-child"
                              >
                                {child}
                              </div>
                            ))}
                        {functionItem.children &&
                          functionItem.children.length > 3 && (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                borderRadius: "15px",
                                backgroundColor: "#E2E2E2",
                                padding: "2px 12px",
                                fontSize: "12px",
                              }}
                            >
                              +{functionItem.children.length - 3}
                            </div>
                          )}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            {/* {ClickOnEdit && (
              <button
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                }}
                onClick={(e) => {
                  openModal(e);
                  setEditFunctionCardClick(false);
                }}
              >
                <div className="exe-readiness-cardmodal">
                  <i class="bi bi-plus-circle"></i>
                  <p> Add from existing functions</p>
                </div>
              </button>
            )} */}
            {isOpen && (
              <div className={"sidenav"}>
                {
                  EditFunctionCardClick && (
                    <div
                      className="gtm-sidebar-header"
                      style={{
                        borderBottom: "1px solid lightgrey",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "var(--sub-heading-font-size)",
                          fontWeight: "var(--heading-font-weight)",
                          paddingLeft: "10px",
                        }}
                      >
                        Assign Owner for Function
                      </div>
                      <a href="#" className="closebtn" onClick={closeModal}>
                        &times;
                      </a>
                    </div>
                  )
                  //  : (
                  //   <div
                  //     className="gtm-sidebar-header"
                  //     style={{
                  //       display: "flex",
                  //       borderBottom: "1px solid lightgrey",
                  //       alignItems: "center",
                  //     }}
                  //   >
                  //     <div
                  //       style={{
                  //         fontSize: "var(--sub-heading-font-size)",
                  //         fontWeight: "var(--heading-font-weight)",
                  //         paddingLeft: "10px",
                  //       }}
                  //     >
                  //       {!addExisting
                  //         ? "Add New Function"
                  //         : "Add Existing Functions"}
                  //       Execution Readiness &gt; Add from existing functions
                  //     </div>
                  //     <a href="#" className="closebtn" onClick={closeModal}>
                  //       &times;
                  //     </a>
                  //   </div>
                  // )}
                }
                {EditFunctionCardClick ? (
                  <div className="gtm-sidebar-body">
                    <div className="row">
                      {functionList && functionList.length > 0 && (
                        <table className="table">
                          <thead>
                            <tr>
                              <th style={{ width: "50%" }}>Function Name</th>
                              <th style={{ width: "50%" }}>Owner</th>
                            </tr>
                          </thead>
                          <tbody>
                            {functionList.map((item, index) => (
                              <tr key={index}>
                                <td
                                  style={{
                                    textAlign: "left",
                                    fontWeight: "bold",
                                    verticalAlign: "middle",
                                    paddingLeft: "20px",
                                  }}
                                >
                                  {item.name}
                                </td>
                                <td>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Select
                                      required
                                      name={`emp_id_${index}`}
                                      styles={customStyles}
                                      className="form-control selectr"
                                      placeholder={
                                        <span
                                          style={{
                                            fontSize: "var(--text-font-size)",
                                          }}
                                        >
                                          Assign owner for {item.name}
                                        </span>
                                      }
                                      value={item?.assigned_to
                                        ?.filter((each) => each.is_active)
                                        ?.map((user) => ({
                                          value: user.value,
                                          label: user.label,
                                          icon: createImageFromInitials(
                                            30,
                                            user.label,
                                            getRandomColor(user.label)
                                          ),
                                        }))}
                                      options={emp_data}
                                      onChange={(selectedOption) =>
                                        handleChange(selectedOption, item.id)
                                      }
                                      isMulti
                                      isSearchable={true}
                                      filterOption={customFilter}
                                      getOptionLabel={(e) => (
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <img alt="" src={e.icon} />
                                          <span
                                            style={{
                                              marginLeft: 5,
                                              fontSize: "var(--text-font-size)",
                                            }}
                                          >
                                            {e.label}
                                          </span>
                                        </div>
                                      )}
                                      components={{
                                        DropdownIndicator: () => null,
                                        IndicatorSeparator: () => null,
                                      }}
                                      isDisabled={
                                        selectedPermission === "write" ||
                                        selectedPermission === "admin"
                                          ? false
                                          : true
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}

                      <div>
                        <Button
                          className="save-button-style-rhpo"
                          onClick={(e) => handleOwnerSubmit()}
                          disabled={
                            selectedPermission === "write" ||
                            selectedPermission === "admin"
                              ? false
                              : true
                          }
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                  // <div className="gtm-sidebar-body">
                  //   {!addExisting ? (
                  //     <>
                  //       <div style={{ height: 550 }}>
                  //         <div className="row">
                  //           <div
                  //             className="col-6"
                  //             style={{
                  //               display: "flex",
                  //               alignItems: "center",
                  //               marginBottom: "10px",
                  //               zIndex: 2000,
                  //               fontSize: "var(--sub-heading-font-size)",
                  //             }}
                  //           >
                  //             <h6>Name</h6>
                  //             <input
                  //               style={{
                  //                 marginLeft: "20px",
                  //                 fontSize: "var(--sub-heading-font-size)",
                  //               }}
                  //               className="form-control"
                  //               value={formData.name}
                  //               placeholder="Add Function name"
                  //               onChange={(e) =>
                  //                 handleInputChange("name", e.target.value, e)
                  //               }
                  //             />
                  //           </div>
                  //         </div>
                  //         <div className="row">
                  //           <div
                  //             className="col-6"
                  //             style={{
                  //               display: "flex",
                  //               alignItems: "center",
                  //               marginBottom: "10px",
                  //               zIndex: 2000,
                  //             }}
                  //           >
                  //             <h6 style={{ marginRight: "10px" }}>Owner</h6>
                  //             <Select
                  //               required
                  //               name="emp_id"
                  //               styles={customStyles}
                  //               className="form-control selectr"
                  //               placeholder={
                  //                 <span
                  //                   style={{
                  //                     fontSize: "var(--sub-heading-font-size)",
                  //                   }}
                  //                 >
                  //                   Assign owner to Business Initiative
                  //                 </span>
                  //               }
                  //               value={selectedOption}
                  //               options={emp_data}
                  //               onChange={handleChange}
                  //               isSearchable={true}
                  //               filterOption={customFilter}
                  //               getOptionLabel={(e) => (
                  //                 <div
                  //                   style={{
                  //                     display: "flex",
                  //                     alignItems: "center",
                  //                   }}
                  //                 >
                  //                   <img alt="" src={e.icon} />
                  //                   <span
                  //                     style={{
                  //                       marginLeft: 5,
                  //                       fontSize:
                  //                         "var(--sub-heading-font-size)",
                  //                     }}
                  //                   >
                  //                     {e.label}
                  //                   </span>
                  //                 </div>
                  //               )}
                  //               components={{
                  //                 DropdownIndicator: () => null,
                  //                 IndicatorSeparator: () => null,
                  //                 // Option: EmpIconOption,
                  //               }}
                  //             />
                  //           </div>
                  //         </div>
                  //         <div style={{display:'flex', alignItems:'center'}}>
                  //           <label style={{marginRight:'10px'}}>Add from existing functions</label>
                  //           <Button
                  //             style={{
                  //               color: "white",
                  //               paddingTop: "10px",
                  //               width: "fit-content",
                  //               backgroundColor: "#0A5F59",
                  //               borderColor: "#0A5F59",
                  //               fontSize: "var(--sub-heading-font-size)",
                  //               alignItems: "center",
                  //             }}
                  //             onClick={(e) => setAddExisting(true)}
                  //           >
                  //             <div>Add</div>
                  //           </Button>
                  //         </div>
                  //       </div>
                  //       {!addExisting ? (
                  //         <>
                  //           <div>
                  //             <Button
                  //               type="button"
                  //               style={{ bottom: "0px" }}
                  //               className="save-button-style-rhpo"
                  //               onClick={(e) => handleSubmit()}
                  //             >
                  //               Save
                  //             </Button>
                  //           </div>
                  //         </>
                  //       ) : (
                  //         <>
                  //           <div>
                  //             <Button
                  //               type="button"
                  //               className="save-button-style-rhpo"
                  //               onClick={(e) => handleSubmit()}
                  //             >
                  //               Save
                  //             </Button>
                  //           </div>
                  //         </>
                  //       )}
                  //     </>
                  //   ) : (
                  //     <>
                  //   <div
                  //     style={{
                  //       height: " 100%",
                  //       overflowY: "auto",
                  //       fontFamily: "Source Sans Pro",
                  //     }}
                  //   >
                  //     {functionData && functionData.length > 0 && (
                  //       <div>
                  //         {functionData.map((funItem, funIndex) => (
                  //           <div
                  //             key={funItem.id}
                  //             style={{
                  //               boxShadow: "0px 2px 4px 0px #32302c1a",
                  //               padding: "5px",
                  //               margin: "10px",
                  //             }}
                  //           >
                  //             <input
                  //               type="checkbox"
                  //               className="form-check-input"
                  //               id={funItem.id}
                  //               checked={checkboxStates[funItem.id] || false}
                  //               onChange={() =>
                  //                 handleCheckboxChange(funItem.id)
                  //               }
                  //             />
                  //             <label
                  //               className="form-check-label"
                  //               style={{
                  //                 paddingLeft: "10px",
                  //                 fontSize: "var(--text-font-size)",
                  //               }}
                  //               htmlFor={`checkbox_${funIndex}`}
                  //             >
                  //               <div>{funItem.name}</div>
                  //             </label>
                  //           </div>
                  //         ))}
                  //       </div>
                  //     )}
                  //   </div>
                  //   <div>
                  //     <Button
                  //       type="button"
                  //       className="save-button-style-rhpo"
                  //       onClick={(e) => handleSubmit()}
                  //     >
                  //       Save
                  //     </Button>
                  //   </div>
                  //   </>
                  //   )}
                  // </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div>
          <CommentSection />
          {selectedHelpInfoSidebar && <SideNavHelpInfo />}
        </div>
      </motion.div>
    </>
  );

  if (!localStorage.getItem("authenticated")) {
    return (
      <div>
        <Login />
      </div>
    );
  } else {
    return (
      <MainComponent
        maincontent={content}
        showSelector={true}
        showButtons={true}
        showProducts={true}
        breadcrumbParent={"Enterprise Readiness"}
        breadcrumbChild={"Execution Readiness"}
        handleNext={handleClick}
        handleBackButton={handleBack}
        documentName={"Execution Readiness"}
        helpKey={keys["Execution Readiness"].Name}
        image={"question"}
        ClickOnEdit={ClickOnEdit}
        handleExecutionEditClick={() => handleEditClick()}
        handleExecutionSaveClick={() => handleSaveClick()}
        showExecutionEditButton={true}
        selectedPermission={selectedPermission}
      />
    );
  }
}

export default ExecutionReadiness;
