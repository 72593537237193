import React, { useState, useEffect, useReducer } from "react";
import "./VCSelection.css";
import { useSelector, useDispatch } from "react-redux";
import { Button, Form } from "react-bootstrap";
import MainComponent from "../../MainComponent";
import Select from "react-select";
import { url } from "../../../store/sagas/API.js";
import { useNavigate } from "react-router-dom";
import {
  addCustomerVCSelection,
  biDetails,
  bisubsectorGet,
  getCustomerVCSelection,
  masterVCtoCustomerVC,
  removeCustomerVCSelection,
  resetExceptionError,
  vcCreate,
  vcListGet,
  vcSelectedGet,
} from "../../../store/actions";
import VCTable from "../../VCTable";
import OtherImage from "../../../assets/images/Othericon.svg";
import AddColor from "../../../assets/images/Plus.svg";
import AddIcon from "../../../assets/images/add.svg";
import EditLight from "../../../assets/images/edit_light.svg";
import Cancel from "../../../assets/images/Cancel.svg";
import VCTabs from "../../VCTabs";
import VCEditModal from "../../VCEditModalTest";
import SearchableDropdown from "../../SearchableDropDown";
import {
  ValueChainInitialState,
  ValueChainMappingReducer,
} from "../../../store/state/ValueChainReducer";
import {
  InitValueChain,
  OnColumnsSelectionChanged,
  OnMenuChange,
  OnProductSelectionChanged,
  OnVCAdd,
  OnVCChange,
  OnVCRemove,
  toggleEditModal,
} from "../../../store/state/ValueChainActions";
import HelpInfo from "../../../utils/helpInfoProviderComponent";
import keys from "../../../utils/helpInfoKeys.json";
import Alert from "../../Alert.js";
import CommentSection from "../../../utils/CommentSection.js";
import Login from "../../Login.js";
import SideNavHelpInfo from "../../../utils/SideNavHelpInfo.js";
import { motion } from "motion/react";

const VCSelection = () => {
  const [refreshBit, updateRefreshBit] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [vcUniqueName, setVCUniqueName] = useState("");
  const [vcNameAlreadyExists, setVCNameAlreadyExists] = useState(false);
  const [divisions, setDivisions] = useState([]);
  const selectedPermission = useSelector((state) => state.selectedPermission);
  const isVCFetched = useSelector((state) => state.isVCFetched);
  const isGetVCAdded = useSelector((state) => state.isGetVCAdded);
  const isVCFetchedAfterAdd = useSelector((state) => state.isVCFetchedAfterAdd);
  const isVCDataFetched = useSelector((state) => state.isVCDataFetched);
  const vcListGetData = useSelector((state) => state.vcListGetData);
  const vcSelectedGetData = useSelector((state) => state.vcSelectedGetData);
  const vcCreateGetData = useSelector((state) => state.vcCreateGetData);
  const vcChanged = useSelector((state) => state.vcChanged);
  const vcAdded = useSelector((state) => state.vcAdded);
  const addCustomerVCSelectionData = useSelector(
    (state) => state.addCustomerVCSelectionData
  );
  const isAddCustomerVCSelection = useSelector(
    (state) => state.isAddCustomerVCSelection
  );
  const isGetCustomerVCSelection = useSelector(
    (state) => state.isGetCustomerVCSelection
  );
  const getCustomerVCSelectionData = useSelector(
    (state) => state.getCustomerVCSelectionData
  );
  const dispatch = useDispatch();
  const [vcListData, setVCListData] = useState([]);
  const [selectedVC, setSelectedVC] = useState(null);
  const [selectedVCData, setSelectedVCData] = useState(null);
  const [isVCChanged, setIsVCChanged] = useState(false);
  const [isVCAdded, setIsVCAdded] = useState(false);
  const [isVCListAfterAdded, setIsVCListAfterAdded] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showProducts, setShowProducts] = useState(null);
  const [mappingState, dispatchVcAction] = useReducer(
    ValueChainMappingReducer,
    ValueChainInitialState
  );
  const [selectedSector, setSelectedSector] = useState(null);
  const [biSectorData, setBiSectorData] = useState([]);
  const [selectedSubSector, setSelectedSubSector] = useState(null);
  const [biSubSectorData, setBiSubSectorData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [valueChainpopup, setValueChainpopup] = useState(false);
  const [updateVCData, setUpdatedVCData] = useState([]);
  const [sectorChange, setSectorChange] = useState(false);
  const isSectorChanged = useSelector((state) => state.isSectorChanged);
  const biSubSectorGetDetails = useSelector(
    (state) => state.biSubSectorGetDetails
  );
  const isBISubSectorFetched = useSelector(
    (state) => state.isBISubSectorFetched
  );
  const selectedHelpInfoSidebar = useSelector(
    (state) => state.selectedHelpInfoSidebar
  );
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "300px",
      display: "inline-block",
      border: "1px solid #ccc",
      boxSizing: "border-box",
      paddingLeft: 0,
      borderRadius: "10px",
    }),
    img: {
      height: 40,
    },
  };
  const navigate = useNavigate();
  let products = 0;
  let totalProducts = 0;
  let productOptions = [];
  if (mappingState.valueChains.activeValueChain) {
    products = mappingState.valueChains.activeValueChain.data.filter(
      (c) => c.isactive === true
    ).length;
    totalProducts = mappingState.valueChains.activeValueChain.data.length;
    productOptions = mappingState.valueChains.activeValueChain.data
      .filter((c) => c.isactive === false)
      .map((obj, i) => {
        return {
          value: obj.id,
          label: obj.product_name,
        };
      });
  }
  const productsLength = products;
  const totalProductsLength = totalProducts;
  const [selectedProducts, setSelectedProducts] = useState([]);
  const handleSelect = (e) => {
    const selectedValues = [e.value];
    setSelectedSector(e);
    setSectorChange(!sectorChange);
    const data = {
      bi_id: localStorage.getItem("bi_id") || null,
      sec_name: selectedValues,
    };
    dispatch(bisubsectorGet(data, sectorChange));
  };
  const handleSubSectorSelect = (e) => {
    setSelectedSubSector(e);
  };
  const handlePageRefreshBit = (e) => {
    updateRefreshBit(Date.parse(new Date()).toString());
  };
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    if (biSubSectorGetDetails) {
      if (biSubSectorGetDetails.ok) {
        let selectedSubSectors = [];
        setBiSubSectorData(
          biSubSectorGetDetails?.data?.data
            .filter((item) => item.isselected === "true")
            .map((obj, i) => {
              if (selectedSubSector?.some((sub) => obj.id === sub.value)) {
                selectedSubSectors.push({
                  value: obj.id,
                  label: obj.prop_name,
                  parent: obj.sec_name,
                  icon: obj.icon ? url + obj.icon : OtherImage,
                });
              }
              return {
                value: obj.id,
                label: obj.prop_name,
                parent: obj.sec_name,
                icon: obj.icon ? url + obj.icon : OtherImage,
              };
            })
        );
        setSelectedSubSector(selectedSubSectors);
      }
    }
  }, [isSectorChanged, isBISubSectorFetched]);
  useEffect(() => {
    dispatch(vcListGet(localStorage.getItem("bi_id")));
    dispatch(getCustomerVCSelection(localStorage.getItem("bi_id")));
    if (localStorage.getItem("vc_json")) {
      dispatchVcAction(
        InitValueChain(JSON.parse(localStorage.getItem("vc_json")))
      );
    } else {
      dispatchVcAction(InitValueChain([]));
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (isGetCustomerVCSelection) {
      if (getCustomerVCSelectionData) {
        if (getCustomerVCSelectionData.ok) {
          if (getCustomerVCSelectionData.data.message) {
            dispatchVcAction(
              InitValueChain(getCustomerVCSelectionData.data.message)
            );
          } else {
            if (localStorage.getItem("vc_json")) {
              dispatchVcAction(
                InitValueChain(JSON.parse(localStorage.getItem("vc_json")))
              );
            }
          }
        }
      }
    }
  }, [isGetCustomerVCSelection]);
  useEffect(() => {
    if (getCustomerVCSelectionData?.data?.status) {
      if (getCustomerVCSelectionData?.data?.status === 400) {
        setVCNameAlreadyExists(true);
      } else if (getCustomerVCSelectionData?.data?.status === 200) {
        var check = false;
        setValueChainpopup(false);
        if (selectedVCData) {
          const updatedSelectedVCData = {
            ...selectedVCData,
            value: vcUniqueName || "",
            sector_id: selectedSector.value || "",
            sub_sector_id: selectedSubSector.value || "",
          };
          setUpdatedVCData(updatedSelectedVCData);
          if (mappingState.valueChains.length > 0) {
            mappingState.valueChains.map((vc) => {
              if (updatedSelectedVCData.key === vc.key) {
                check = true;
              }
            });
          } else {
            dispatchVcAction(OnVCAdd(updatedSelectedVCData));
            check = true;
          }
          if (!check) {
            dispatchVcAction(OnVCAdd(updatedSelectedVCData));
          }
        }
      }
    }
  }, [getCustomerVCSelectionData]);
  useEffect(() => {
    if (isGetVCAdded) {
      if (vcCreateGetData?.data?.status === 400) {
        setVCNameAlreadyExists(true);
      } else {
        closeModal();
        setVCNameAlreadyExists(false);
      }
    }
  }, [isGetVCAdded, vcCreateGetData]);

  useEffect(() => {
    if (isVCFetched) {
      if (vcListGetData) {
        if (vcListGetData.ok) {
          setVCListData(vcListGetData.data.message);
          setBiSectorData(
            vcListGetData.data.mst_sec
              .filter((obj) => obj.isselected === "true")
              .map((obj) => ({
                value: obj.id,
                label: obj.prop_name,
                icon: obj.icon ? url + obj.icon : OtherImage,
              }))
          );
          setBiSubSectorData(
            vcListGetData.data.sub_sec &&
              vcListGetData.data.sub_sec
                .filter((obj) => obj.isselected === "true")
                .map((obj) => ({
                  value: obj.id,
                  label: obj.prop_name,
                  icon: obj.icon ? url + obj.icon : OtherImage,
                }))
          );
        } else {
          return <div>{alert(vcListGetData.data)}</div>;
        }
      } else {
        return <div>{alert("Not able to fetch the data")}</div>;
      }
    }
  }, [isVCFetched, isVCFetchedAfterAdd]);

  useEffect(() => {
    if (isGetVCAdded) {
      if (vcCreateGetData) {
        if (vcCreateGetData.ok) {
          setIsVCListAfterAdded(!isVCListAfterAdded);
          dispatch(
            vcListGet(localStorage.getItem("bi_id"), isVCListAfterAdded)
          );
        } else {
          return <div>{alert(vcCreateGetData.data)}</div>;
        }
      } else {
        return <div>{alert("Not able to fetch the data")}</div>;
      }
    }
  }, [isGetVCAdded, vcAdded]);

  const handleAddDivision = () => {
    setDivisions([
      ...divisions,
      {
        divisionValue: "",
        dimension: [],
      },
    ]);
  };

  const handleRemoveDivision = (divisionIndex) => {
    const updatedDivisions = [...divisions];
    updatedDivisions.splice(divisionIndex, 1);
    setDivisions(updatedDivisions);
  };

  const handleDivisionInputChange = (divisionIndex, e) => {
    const updatedDivisions = [...divisions];
    updatedDivisions[divisionIndex] = {
      ...updatedDivisions[divisionIndex],
      divisionValue: e.target.value,
    };
    setDivisions(updatedDivisions);
  };

  const handleNestedDivisionChange = (
    divisionIndex,
    nestedIndex,
    e,
    dimension = null
  ) => {
    const updatedDivisions = [...divisions];
    updatedDivisions[divisionIndex].dimension[nestedIndex] = e.target.value;
    if (dimension !== null) {
      updatedDivisions[divisionIndex].dimension[nestedIndex].dimension.push({
        name: "",
        key: (Math.random() * 10000).toString(),
        dimension: [],
      });
    } else {
      updatedDivisions[divisionIndex].dimension[nestedIndex].name =
        e.target.value;
    }
    setDivisions(updatedDivisions);
  };

  const handleAddNestedDivision = (divisionIndex) => {
    const updatedDivisions = [...divisions];
    updatedDivisions[divisionIndex].dimension.push({
      name: "",
      key: Date.parse(new Date()).toString(),
      dimension: [],
    });
    setDivisions(updatedDivisions);
  };

  const handleRemoveNestedDivision = (divisionIndex, nestedIndex) => {
    const updatedDivisions = [...divisions];
    updatedDivisions[divisionIndex].dimension.splice(nestedIndex, 1);
    setDivisions(updatedDivisions);
  };
  const handleRemoveNestedChildDivision = (key) => {
    let updatedDivisions = [...divisions];
    updatedDivisions = findParent(updatedDivisions, key);
    setDivisions(updatedDivisions);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isAnyInputNull =
      inputValue.trim() === "" ||
      divisions.some(
        (division) =>
          division.divisionValue.trim() === "" ||
          division.dimension.some((el) => el.name.trim() === "")
      );

    if (isAnyInputNull) {
      alert("Please fill in all input fields before submitting.");
      return;
    }

    if (divisions.length === 0) {
      alert("Please add at least one division before submitting.");
      return;
    }
    setIsVCAdded(!isVCAdded);
    const formData = {
      sector_id: selectedSector.value || "",
      sub_sector_id: selectedSubSector.value || "",
      vcname: inputValue,
      category: divisions.map((division) => ({
        name: division.divisionValue,
        dimension: division.dimension,
      })),
    };
    dispatch(vcCreate(formData, localStorage.getItem("bi_id"), isVCAdded));
  };
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
    setValueChainpopup(false);
  };
  const handleAddVC = (e) => {
    setInputValue("");
    setDivisions([]);
    openModal();
  };
  const handleClose = (e) => {
    closeModal();
  };
  const handleOnchange = (e) => {
    setSelectedVCData(e);
    setSelectedVC(e.label);
    setIsVCChanged(true);
    if (e.is_master == true) {
      setValueChainpopup(true);
    } else {
      var check = false;
      if (mappingState.valueChains.length > 0) {
        mappingState.valueChains.map((vc) => {
          if (e.key === vc.key) {
            check = true;
          }
        });
        // setShowEditModal(!showEditModal);
      } else {
        dispatchVcAction(OnVCAdd(e));
        check = true;
      }
      if (!check) {
        dispatchVcAction(OnVCAdd(e));
      }
    }
  };
  const handleVCUniqueNameChange = (e) => {
    setVCUniqueName(e.target.value);
  };
  const handleVCSubmit = (e) => {
    e.preventDefault();
    dispatch(
      getCustomerVCSelection(
        localStorage.getItem("bi_id"),
        vcUniqueName,
        "",
        "",
        "",
        true
      )
    );
  };
  useEffect(() => {
    if (addCustomerVCSelectionData?.data?.status === 200) {
      navigate("/vcmapping");
    }
  }, [addCustomerVCSelectionData]);

  const handleClick = () => {
    if (localStorage.getItem("bi_id")) {
      var products = document.getElementsByClassName("addedvc-menu");
      if (products.length > 0) {
        if (selectedPermission === "read") {
          navigate("/vcmapping");
        }
        if (selectedPermission === "write" || selectedPermission === "admin") {
          if (selectedSector && selectedSubSector) {
            mappingState.valueChains.push({
              sector_id: selectedSector.value || "",
              sub_sector_id: selectedSubSector.value || "",
            });
          }
          dispatch(
            addCustomerVCSelection(
              localStorage.getItem("bi_id"),
              mappingState.valueChains,
              true
            )
          );
        }
      } else {
        <div>{alert("Please select and add Value Chains")}</div>;
      }
    } else {
      <div>{alert("Please select Business Initiative")}</div>;
    }
  };
  const handleBack = () => {
    navigate("/xlstrategymatrix", { replace: true });
  };

  const onEdit = (vc) => {
    dispatchVcAction(toggleEditModal(vc));
    setShowEditModal(!showEditModal);
  };
  const onMenuChanged = (category, vcKey) => {
    dispatchVcAction(OnMenuChange(category, vcKey));
  };
  const onSave = (updatedValueChain) => {
    // console.log("updatedValueChain", updatedValueChain);
    dispatchVcAction(OnColumnsSelectionChanged(updatedValueChain));
    setShowEditModal(!showEditModal);
    // dispatchVcAction(toggleEditModal(updatedValueChain.key));
  };
  const handleVCChange = (e) => {
    dispatchVcAction(OnVCChange(e));
  };
  const handleRemoveVC = (e) => {
    dispatchVcAction(OnVCRemove(e.key));
    dispatch(
      removeCustomerVCSelection(localStorage.getItem("bi_id"), e.key, false)
    );
  };
  const onClose = () => {
    setShowEditModal(!showEditModal);
  };
  const handleRemoveProduct = (productKey, valueChain) => {
    let updatedProducts = valueChain.data.map((c) => {
      return c.id === productKey ? { ...c, isactive: !c.isactive } : c;
    });
    let updatedValueChain = {
      ...valueChain,
      data: updatedProducts,
    };
    dispatchVcAction(OnProductSelectionChanged(updatedValueChain));
  };
  const handleAddSelectedProductsPopup = (productKey, valueChain) => {
    setShowPopup(false);
    setShowProducts(null);
    if (productKey) {
      if (productKey.length > 0) {
        let updatedProducts = [...valueChain.data];
        productKey.map((obj) => {
          updatedProducts = updatedProducts.map((c) => {
            if (c.id === obj.value) {
              c = { ...c, isactive: !c.isactive };
            }
            return c;
          });
        });
        let updatedValueChain = {
          ...valueChain,
          data: updatedProducts,
        };

        dispatchVcAction(OnProductSelectionChanged(updatedValueChain));
        setSelectedProducts([]);
      }
    }
  };
  function findParent(items, key) {
    items = items.map((c) => {
      const objWithIdIndex = c.dimension.findIndex((obj) => obj.key === key);
      if (objWithIdIndex == -1) {
        findParent(c.dimension, key);
      } else {
        c.dimension.splice(objWithIdIndex, 1);
      }
      return c;
    });
    return items;
  }
  const handleProductPopupClose = () => {
    setShowPopup(false);
  };
  const handleAddProductsPopup = () => {
    setShowPopup(true);
  };
  const handleChangeProductPopup = (e) => {
    if (e.target.checked) {
      setSelectedProducts((state) => {
        let productsState = [...state];
        productsState.push({
          value: e.target.getAttribute("data-id"),
          label: e.target.getAttribute("data-value"),
        });
        return productsState;
      });
    } else {
      setSelectedProducts((state) => {
        let productsState = [...state];
        const objWithIdIndex = productsState.findIndex(
          (obj) => obj.value === e.target.getAttribute("data-id")
        );
        selectedProducts.splice(objWithIdIndex, 1);
        return productsState;
      });
    }
  };
  const subject = encodeURIComponent("Request to add a Value Chain");
  const body = encodeURIComponent(
    "Hi, \n\nThe recommended value chain for the selected target sectors is not available. Please add a value chain. Thanks."
  );
  const email = "support@excelrate.in";
  const content = (
    <motion.div
      initial={{ y: "100%" }}
      animate={{ y: 0 }}
      transition={{ duration: 0.8, ease: "easeInOut" }}
      style={{ height: "100%" }}
    >
      <div style={{ display: "flex" }}>
        <div
          style={{
            fontSize: "var(--main-heading)",
            fontWeight: "var(--heading-font-weight)",
          }}
        >
          Value Chain Selection
        </div>
        <span style={{ margin: "5px" }}>
          <HelpInfo
            documentName={"Value Chain Selection"}
            helpKey={keys["Value Chain Selection"].VCInfo}
          />
        </span>
      </div>
      <br />
      <div style={{ fontSize: "var(--sub-heading-font-size)" }}>
        Please select a relevant Value Chain
      </div>
      <div>
        <div style={{ display: "flex" }}>
          <SearchableDropdown
            options={vcListData}
            label="value"
            id="key"
            selector="is_master"
            selectedVal={selectedVC}
            handleChange={handleOnchange}
            selectedPermission={selectedPermission}
          />
          {(selectedPermission === "write" ||
            selectedPermission === "admin") && (
            // <button className="addvc" onClick={handleAddVC}>
            //   <img
            //     src={AddIcon}
            //     width={16}
            //     height={16}
            //     style={{ marginRight: "5px" }}
            //   />
            //   Add New Value Chain
            // </button>
            <a
              style={{ textDecoration: "none" }}
              href={`mailto:${email}?subject=${subject}&body=${body}`}
            >
              <button className="addvc">Request a Value Chain</button>
            </a>
          )}
        </div>
        {vcListData && !vcListData.length > 0 && (
          <div>
            No relevant valuechains are found. Please click "Request a
            Valuechain" button to send us a message
          </div>
        )}
        <hr />
        {mappingState.valueChains.length > 0 && (
          <div style={{ display: "flex", width: "65%", whiteSpace: "nowrap" }}>
            {mappingState.valueChains.map((vc) => (
              <div
                className={
                  mappingState.valueChains.activeValueChain.key === vc.key
                    ? "addedvc-menu active"
                    : "addedvc-menu"
                }
              >
                <div
                  style={{ fontSize: "var(--text-font-size)" }}
                  key={vc.key}
                  data-menu-id={vc.key}
                  data-selector={vc.is_master}
                  onClick={() => handleVCChange(vc)}
                >
                  {vc.value}
                </div>
                {(selectedPermission === "write" ||
                  selectedPermission === "admin") && (
                  <>
                    <button
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                      type="button"
                      onClick={(e) => onEdit(vc)}
                    >
                      <img src={EditLight} />
                    </button>
                    {selectedPermission === "admin" && (
                      <button
                        style={{
                          marginLeft: 5,
                          height: 17,
                          width: 17,
                          border: "thin ridge",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          backgroundColor: "transparent",
                          border: "none",
                        }}
                        type="button"
                        onClick={(e) => handleRemoveVC(vc)}
                      >
                        <img src={Cancel} />
                      </button>
                    )}
                  </>
                )}
              </div>
            ))}
          </div>
        )}
        {mappingState.valueChains.activeValueChain &&
          productsLength !== totalProductsLength &&
          !showPopup && (
            <button
              style={{ backgroundColor: "white", border: 0 }}
              onClick={handleAddProductsPopup}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src={AddColor} width={18} height={18} />
                <span
                  style={{
                    color: "#0A5F59",
                    fontWeight: "var(--heading-font-weight)",
                    fontSize: "var(--text-font-size: 16px)",
                    marginLeft: "5px",
                  }}
                >
                  Add Product
                </span>
              </div>
            </button>
          )}
      </div>
      <div style={{ marginTop: 5, maxHeight: "650px" }}>
        {isOpen && (
          <motion.div
            initial={{ x: "100%" }}
            animate={{ x: 1 }}
            exit={{ x: "100%" }}
            transition={{ duration: 0.8, ease: "easeInOut" }}
            style={{
              zIndex: 999,
            }}
            className={"sidenav"}
          >
            <div
              className="gtm-sidebar-header"
              style={{
                display: "flex",
                borderBottom: "1px solid lightgrey",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontSize: "var(--sub-heading-font-size)",
                  fontWeight: "var(--heading-font-weight)",
                  paddingLeft: "10px",
                }}
              >
                Value chain selection &gt; Create new value chain
              </div>
              <a href="#" className="closebtn" onClick={closeModal}>
                &times;
              </a>
            </div>
            <div style={{ paddingLeft: "20px" }}>
              <form onSubmit={handleSubmit}>
                <div>
                  <div style={{ display: "flex", paddingTop: "10px" }}>
                    <Form.Group
                      style={{
                        width: 300,
                        fontSize: "var(--sub-heading-font-size)",
                        fontWeight: "var(--heading-font-weight)",
                      }}
                    >
                      <Form.Label>Target Sectors</Form.Label>
                      <Select
                        required
                        name="sector"
                        styles={customStyles}
                        className="form-control selectr"
                        placeholder={
                          <span style={{ fontSize: "var(--text-font-size)" }}>
                            Select Sector
                          </span>
                        }
                        value={selectedSector}
                        options={biSectorData}
                        onChange={handleSelect}
                        isSearchable={true}
                        getOptionLabel={(e) => (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img alt="" style={{ height: 30 }} src={e.icon} />
                            <span
                              style={{
                                marginLeft: 5,
                                fontSize: "var(--text-font-size)",
                              }}
                            >
                              {e.label}
                            </span>
                          </div>
                        )}
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                          // Option: IconOption,
                        }}
                        isDisabled={
                          selectedPermission === "write" ||
                          selectedPermission === "admin"
                            ? false
                            : true
                        }
                      />
                    </Form.Group>
                    <Form.Group
                      style={{
                        width: 300,
                        marginLeft: 20,
                        fontSize: "var(--sub-heading-font-size)",
                        fontWeight: "var(--heading-font-weight)",
                      }}
                    >
                      <Form.Label>Target Sub Sectors</Form.Label>
                      <Select
                        required
                        name="subsector"
                        styles={customStyles}
                        className="form-control selectr"
                        placeholder={
                          <span style={{ fontSize: "var(--text-font-size)" }}>
                            Select Sub Sector
                          </span>
                        }
                        value={selectedSubSector}
                        options={biSubSectorData}
                        onChange={handleSubSectorSelect}
                        isSearchable={true}
                        getOptionLabel={(e) => (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img alt="" style={{ height: 30 }} src={e.icon} />
                            <span style={{ marginLeft: 5 }}>{e.label}</span>
                          </div>
                        )}
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                          // Option: IconOption,
                        }}
                        isDisabled={
                          selectedPermission === "write" ||
                          selectedPermission === "admin"
                            ? false
                            : true
                        }
                      />
                    </Form.Group>
                  </div>
                </div>
                <label
                  style={{
                    marginTop: 10,
                    fontSize: "var(--sub-heading-font-size)",
                    fontWeight: "var(--heading-font-weight)",
                  }}
                >
                  Value Chain Name
                  <input
                    className="form-control addvc-input"
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    required
                  />
                </label>
                {vcNameAlreadyExists && (
                  <div
                    style={{
                      color: "var(--required-color)",
                      fontWeight: "500",
                    }}
                  >
                    *This value-chain name already exists, please enter a unique
                    name for the value chain.
                  </div>
                )}
                <div style={{ height: 550 }}>
                  <div>
                    {divisions.map((division, divisionIndex) => (
                      <div
                        key={divisionIndex}
                        style={{
                          border: "thin ridge",
                          borderRadius: 20,
                          marginTop: 10,
                        }}
                      >
                        {divisionIndex !== 0 && (
                          <button
                            style={{
                              float: "right",
                              borderRadius: 10,
                              background: "white",
                              border: "thin ridge",
                              margin: 10,
                            }}
                            type="button"
                            onClick={() => handleRemoveDivision(divisionIndex)}
                          >
                            Remove Category
                          </button>
                        )}
                        <label
                          style={{
                            paddingLeft: 20,
                            paddingTop: 10,
                            fontSize: "var(--sub-heading-font-size)",
                          }}
                        >
                          Category
                          <input
                            style={{
                              paddingLeft: 20,
                              fontSize: "var(--text-font-size)",
                            }}
                            className="form-control addvc-input"
                            required
                            type="text"
                            value={division.divisionValue}
                            onChange={(e) =>
                              handleDivisionInputChange(divisionIndex, e)
                            }
                          />
                        </label>
                        <div>
                          {division.dimension.map((list, nestedIndex) => (
                            <>
                              <Dimensions
                                refreshBit={refreshBit}
                                handlePageRefreshBit={handlePageRefreshBit}
                                title={"Dimension"}
                                divisionIndex={divisionIndex}
                                divisionValues={division.dimension}
                                childData={list}
                                childIndex={nestedIndex}
                                handleRemoveNestedDivision={
                                  handleRemoveNestedDivision
                                }
                                handleRemoveNestedChildDivision={
                                  handleRemoveNestedChildDivision
                                }
                                handleNestedDivisionChange={
                                  handleNestedDivisionChange
                                }
                              />
                            </>
                          ))}
                          <button
                            type="button"
                            className="add-dimension"
                            style={{
                              borderRadius: 4,
                              color: "white",
                              marginLeft: 50,
                              marginBottom: 5,
                              border: "none",
                              fontSize: "var(--sub-heading-font-size)",
                              padding: "5px 10px",
                            }}
                            onClick={() =>
                              handleAddNestedDivision(divisionIndex)
                            }
                            disabled={division.dimension.includes("")}
                          >
                            Add Dimension
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                  <button
                    style={{
                      borderRadius: 4,
                      background: "#0A5F59",
                      color: "white",
                      marginTop: 10,
                      border: "none",
                      fontSize: "var(--sub-heading-font-size)",
                      padding: "5px 10px",
                    }}
                    type="button"
                    onClick={handleAddDivision}
                  >
                    Add Category
                  </button>
                  <br />
                </div>
                <Button
                  style={{
                    backgroundColor: "var(--button-primary-color)",
                    fontSize: "var(--sub-heading-font-size)",
                    width: "fit-content",
                    position: "absolute",
                    cursor: "pointer",
                    top: "0px",
                    right: "70px",
                    padding: "4px 10px",
                  }}
                  type="submit"
                >
                  Save
                </Button>
              </form>
            </div>
          </motion.div>
        )}
        {mappingState.valueChains.activeValueChain && (
          <>
            {showPopup && (
              // <div className="modal-overlay">
              //   <div className="modal-content" style={{ height: 300 }}>
              //     <h6 style={{ fontSize: "var(--text-font-size)" }}>
              //       Select Products
              //     </h6>
              //     <hr style={{ borderTop: "1px solid" }} />
              //     <div
              //       className="row"
              //       style={{
              //         display: "flex",
              //         flexWrap: "wrap",
              //         height: 200,
              //       }}
              //     >
              //       {productOptions.map((obj) => {
              //         return (
              //           <div className="col-3">
              //             <label>
              //               <input
              //                 style={{
              //                   marginRight: 5,
              //                   fontSize: "var(--text-font-size)",
              //                 }}
              //                 className="vccheckbox"
              //                 type="checkbox"
              //                 data-id={obj.value}
              //                 data-value={obj.label}
              //                 onChange={handleChangeProductPopup}
              //               />

              //               {obj.label}
              //             </label>
              //           </div>
              //         );
              //       })}
              //     </div>
              //     <div
              //       style={{
              //         display: "flex",
              //         marginTop: 20,
              //         flexWrap: "wrap",
              //         justifyContent: "flex-end",
              //         bottom: 20,
              //       }}
              //     >
              //       <button
              //         style={{
              //           width: "fit-content",
              //           backgroundColor: "#fff",
              //           color: "black",
              //           borderRadius: 5,
              //           border: "thin ridge",
              //           marginRight: 20,
              //         }}
              //         onClick={() => handleProductPopupClose()}
              //       >
              //         Cancel
              //       </button>
              //       <button
              //         style={{
              //           width: "fit-content",
              //           padding:'4px 10px',
              //           backgroundColor: "#0A5F59",
              //           borderColor: "#0A5F59",
              //           color: "white",
              //           borderRadius: 5,
              //           border: 0,
              //         }}
              //         onClick={(e) =>
              //           handleAddSelectedProductsPopup(
              //             selectedProducts,
              //             mappingState.valueChains.activeValueChain
              //           )
              //         }
              //       >
              //         Save
              //       </button>
              //     </div>
              //   </div>
              // </div>
              <motion.div
                initial={{ x: "100%" }}
                animate={{ x: 1 }}
                exit={{ x: "100%" }}
                transition={{ duration: 0.8, ease: "easeInOut" }}
                style={{
                  width: "50%",
                  zIndex: 200,
                }}
                className={"sidenav"}
              >
                <div
                  className="gtm-sidebar-header"
                  style={{
                    display: "flex",
                    borderBottom: "1px solid lightgrey",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: "var(--sub-heading-font-size)",
                      fontWeight: "var(--heading-font-weight)",
                    }}
                  >
                    Value chain selection &gt; Add Product
                  </div>
                  <a
                    href="#"
                    className="closebtn"
                    onClick={() => handleProductPopupClose()}
                  >
                    &times;
                  </a>
                </div>
                <div style={{ paddingTop: "10px" }}>
                  {productOptions.map((obj) => {
                    return (
                      <div
                        className="row"
                        style={{
                          paddingLeft: "10px",
                        }}
                      >
                        <label>
                          <input
                            style={{
                              marginRight: 5,
                              fontSize: "var(--text-font-size)",
                            }}
                            className="vccheckbox"
                            type="checkbox"
                            data-id={obj.value}
                            data-value={obj.label}
                            onChange={handleChangeProductPopup}
                          />
                          {obj.label}
                        </label>
                      </div>
                    );
                  })}
                </div>
                <button
                  style={{
                    backgroundColor: "#0A5F59",
                    borderColor: "#0A5F59",
                    color: "white",
                    borderRadius: 5,
                  }}
                  className="save-button-style-rhpo"
                  onClick={(e) =>
                    handleAddSelectedProductsPopup(
                      selectedProducts,
                      mappingState.valueChains.activeValueChain
                    )
                  }
                >
                  Save
                </button>
              </motion.div>
            )}
            {showEditModal && (
              <motion.div
                initial={{ x: "100%" }}
                animate={{ x: 1 }}
                exit={{ x: "100%" }}
                transition={{ duration: 0.8, ease: "easeInOut" }}
                style={{
                  width: "50%",
                  zIndex: 200,
                }}
                className={"sidenav"}
              >
                <div
                  className="gtm-sidebar-header"
                  style={{
                    borderBottom: "1px solid lightgrey",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: "var(--sub-heading-font-size)",
                      fontWeight: "var(--heading-font-weight)",
                      paddingLeft: "10px",
                    }}
                  >
                    Value chain selection &gt; Customize value chain
                  </div>
                  <a
                    href="#"
                    className="closebtn"
                    onClick={() => setShowEditModal(false)}
                  >
                    &times;
                  </a>
                </div>
                <VCEditModal
                  key={mappingState.valueChains.activeValueChain.key}
                  onSave={onSave}
                  onClose={onClose}
                  selectedMenu={
                    mappingState.valueChains.activeValueChain.activeMenu
                  }
                  vcKey={mappingState.valueChains.activeValueChain.key}
                  vcTitle={mappingState.valueChains.activeValueChain.title}
                  valueChain={mappingState.valueChains.activeValueChain}
                />
              </motion.div>
            )}
            {mappingState.valueChains.activeValueChain.activeMenu && (
              <VCTabs
                menus={mappingState.valueChains.activeValueChain.categories}
                selectedMenu={
                  mappingState.valueChains.activeValueChain.activeMenu
                }
                onMenuChanged={onMenuChanged}
                vcKey={mappingState.valueChains.activeValueChain.key}
              />
            )}
            {mappingState.valueChains.activeValueChain.activeMenu && (
              <div className="vcselection-table">
                <VCTable
                  selectedMenu={
                    mappingState.valueChains.activeValueChain.activeMenu
                  }
                  previewvc={true}
                  valueChain={mappingState.valueChains.activeValueChain}
                  handleRemoveProduct={handleRemoveProduct}
                  showProducts={showProducts}
                  productData={mappingState.valueChains.activeValueChain.data}
                  showProductRemoval={true}
                />
              </div>
            )}
          </>
        )}
        {valueChainpopup && (
          <motion.div
            initial={{ x: "100%" }}
            animate={{ x: 1 }}
            exit={{ x: "100%" }}
            transition={{ duration: 0.8, ease: "easeInOut" }}
            style={{
              width: "50%",
              zIndex: 999,
            }}
            className={"sidenav"}
          >
            <div
              className="gtm-sidebar-header"
              style={{
                borderBottom: "1px solid lightgrey",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  marginBottom: 10,
                  fontSize: "var(--sub-heading-font-size)",
                  fontWeight: "var(--heading-font-weight)",
                }}
              >
                Unique Name for the value chain
              </div>
              <a href="#" className="closebtn" onClick={closeModal}>
                &times;
              </a>
            </div>
            <div style={{ paddingLeft: "20px" }}>
              <form onSubmit={(e) => handleVCSubmit(e)}>
                <label
                  style={{
                    marginTop: 10,
                    fontSize: "var(--sub-heading-font-size)",
                    fontWeight: "var(--heading-font-weight)",
                  }}
                >
                  Please Enter a Unique Name for the Value Chain{" "}
                  <span
                    style={{
                      color: "var(--required-color)",
                      fontWeight: "500",
                    }}
                  >
                    *
                  </span>
                  <input
                    className="form-control addvc-input"
                    type="text"
                    value={vcUniqueName}
                    onChange={(e) => handleVCUniqueNameChange(e)}
                    required
                  />
                  {vcNameAlreadyExists && (
                    <div
                      style={{
                        color: "var(--required-color)",
                        fontWeight: "500",
                      }}
                    >
                      *This value-chain name already exists, please enter a
                      unique name for the value chain.
                    </div>
                  )}
                </label>
                <div style={{ display: "flex", paddingTop: "10px" }}>
                  <Form.Group
                    style={{
                      width: 300,
                      fontSize: "var(--sub-heading-font-size)",
                      fontWeight: "var(--heading-font-weight)",
                    }}
                  >
                    <Form.Label>Target Sectors</Form.Label>
                    <Select
                      required
                      name="sector"
                      styles={customStyles}
                      className="form-control selectr"
                      placeholder={
                        <span style={{ fontSize: "var(--text-font-size)" }}>
                          Select Sector
                        </span>
                      }
                      value={selectedSector}
                      options={biSectorData}
                      onChange={handleSelect}
                      isSearchable={true}
                      getOptionLabel={(e) => (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img alt="" style={{ height: 30 }} src={e.icon} />
                          <span
                            style={{
                              marginLeft: 5,
                              fontSize: "var(--text-font-size)",
                            }}
                          >
                            {e.label}
                          </span>
                        </div>
                      )}
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                        // Option: IconOption,
                      }}
                      isDisabled={
                        selectedPermission === "write" ||
                        selectedPermission === "admin"
                          ? false
                          : true
                      }
                    />
                  </Form.Group>
                  <Form.Group
                    style={{
                      width: 300,
                      marginLeft: 20,
                      fontSize: "var(--sub-heading-font-size)",
                      fontWeight: "var(--heading-font-weight)",
                    }}
                  >
                    <Form.Label>Target Sub Sectors</Form.Label>
                    <Select
                      required
                      name="subsector"
                      styles={customStyles}
                      className="form-control selectr"
                      placeholder={
                        <span style={{ fontSize: "var(--text-font-size)" }}>
                          Select Sub Sector
                        </span>
                      }
                      value={selectedSubSector}
                      options={biSubSectorData}
                      onChange={handleSubSectorSelect}
                      isSearchable={true}
                      getOptionLabel={(e) => (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img alt="" style={{ height: 30 }} src={e.icon} />
                          <span style={{ marginLeft: 5 }}>{e.label}</span>
                        </div>
                      )}
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                        // Option: IconOption,
                      }}
                      isDisabled={
                        selectedPermission === "write" ||
                        selectedPermission === "admin"
                          ? false
                          : true
                      }
                    />
                  </Form.Group>
                </div>
                <div>
                  <Button
                    type="submit"
                    style={{ width: "fit-content", marginTop: "0px" }}
                    className="save-button-style-rhpo"
                    disabled={
                      selectedPermission === "write" ||
                      selectedPermission === "admin"
                        ? false
                        : true
                    }
                  >
                    Save
                  </Button>
                </div>
              </form>
            </div>
          </motion.div>
        )}
      </div>
      <CommentSection />
      {selectedHelpInfoSidebar && <SideNavHelpInfo />}
    </motion.div>
  );
  if (!localStorage.getItem("authenticated")) {
    return (
      <div>
        <Login />
      </div>
    );
  } else {
    return (
      <MainComponent
        maincontent={content}
        handleNext={handleClick}
        handleBackButton={handleBack}
        showSelector={true}
        showButtons={true}
        breadcrumbParent={"Onboarding"}
        breadcrumbChild={"Value Chain Selection"}
        documentName={"Value Chain Selection"}
        helpKey={keys["Value Chain Selection"].Name}
        image={"question"}
      />
    );
  }
};
const Dimensions = ({
  title = "",
  divisionIndex,
  divisionValues,
  childData,
  childIndex,
  handleNestedDivisionChange,
  handleRemoveNestedDivision,
  handleRemoveNestedChildDivision,
  refreshBit,
  handlePageRefreshBit,
  padding = 50,
  child = false,
}) => {
  const [userInput, setUserInput] = useState(childData.name);

  return (
    <>
      <div
        key={childData.key}
        style={{
          paddingLeft: padding,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex" }}>
          <label style={{ fontSize: "var(--sub-heading-font-size)" }}>
            {title}
            <input
              className="form-control addvc-input"
              required
              type="text"
              value={userInput}
              onChange={(e) => {
                setUserInput(e.target.value);
                childData.name = e.target.value;
                handlePageRefreshBit();
              }}
            />
          </label>
          {childIndex !== 0 && !child && (
            <div
              style={{ cursor: "pointer", margin: "35px 10px 0px 10px" }}
              onClick={() => {
                handleRemoveNestedDivision(divisionIndex, childIndex);
              }}
            >
              <img src={Cancel} width={18} height={18} />
            </div>
          )}
          {child && (
            <div
              style={{
                cursor: "pointer",
                marginTop: 35,
                marginLeft: 10,
                marginRight: "10px",
              }}
              onClick={() => {
                handleRemoveNestedChildDivision(childData.key);
              }}
            >
              <img src={Cancel} width={18} height={18} />
            </div>
          )}
          <div
            style={{ cursor: "pointer", margin: "35px 10px 0px 5px" }}
            onClick={() => {
              childData?.dimension.push({
                key: Date.parse(new Date()).toString(),
                name: "",
                dimension: [],
              });
              handlePageRefreshBit();
            }}
          >
            <img src={AddIcon} />
          </div>
        </div>
      </div>
      {childData?.dimension.length > 0 &&
        childData?.dimension.map((list, index) => (
          <Dimensions
            refreshBit={refreshBit}
            handlePageRefreshBit={handlePageRefreshBit}
            title={"Sub Dimension"}
            divisionIndex={childIndex}
            divisionValues={childData}
            childData={list}
            childIndex={index}
            handleNestedDivisionChange={handleNestedDivisionChange}
            handleRemoveNestedChildDivision={handleRemoveNestedChildDivision}
            padding={padding + 20}
            child={true}
          />
        ))}
    </>
  );
};
export default VCSelection;
