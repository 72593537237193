import {
  LOGIN_SUCCESS,
  BI_DETAILS_SUCCESS,
  BI_DETAILS_LIST_SUCCESS,
  BI_SECTOR_POST_SUCCESS,
  BI_SECTOR_GET_SUCCESS,
  BI_DETAILS_GET_SUCCESS,
  BI_DETAILS_POST_SUCCESS,
  BI_SUB_SECTOR_GET_SUCCESS,
  BI_SUB_SECTOR_POST_SUCCESS,
  BI_BUSINESS_TYPE_GET_SUCCESS,
  BI_BUSINESS_TYPE_POST_SUCCESS,
  BI_PRODUCT_DETAILS_GET_SUCCESS,
  BI_PRODUCT_DETAILS_POST_SUCCESS,
  UI_MENU_LIST_SUCCESS,
  VC_LIST_GET_SUCCESS,
  VC_SELECTED_GET_SUCCESS,
  VC_CREATE_GET_SUCCESS,
  VC_CHANGE_TABLE_DATA_SUCCESS,
  ADD_CUSTOMER_VC_SELECTION_SUCCESS,
  GET_CUSTOMER_VC_SELECTION_SUCCESS,
  ADD_CATEGORY_SUCCESS,
  LOADING_START,
  SESSION_EXPIRE_ERROR,
  GET_ORG_DETAILS_SUCCESS,
  SESSION_API_ERROR,
  REMOVE_CUSTOMER_VC_SELECTION_SUCCESS,
  ADD_CURRENT_DOC_URL,
  GET_PRODUCTS_DETAILS_SUCCESS,
  GET_MARKET_SIZE_DETAILS_SUCCESS,
  GET_COMPETITOR_DETAILS_SUCCESS,
  GET_COMPETITOR_ANALYSIS_DETAILS_SUCCESS,
  GET_FETAURE_TYPE_DETAILS_SUCCESS,
  POST_COMPETITOR_ANALYSIS_DETAILS_SUCCESS,
  POST_COMPETITOR_DETAILS_SUCCESS,
  POST_MARKET_SIZE_DETAILS_SUCCESS,
  POST_FETAURE_DETAILS_SUCCESS,
  EXCEPTION_ERROR,
  GET_METRICS_SUCCESS,
  POST_METRICS_SUCCESS,
  POST_MS_DOCS_SUCCESS,
  GET_MS_DOCS_SUCCESS,
  ADD_SCREEN_ID,
  ADD_MAIN_SCREEN_ID,
  GET_NOTES_SUCCESS,
  POST_NOTES_SUCCESS,
  RESET_EXCEPTION_ERROR,
  GET_ANSOFF_MATRIX_DETAILS,
  GET_ANSOFF_MATRIX_DETAILS_SUCCESS,
  POST_MEETING_SUCCESS,
  GET_MEETING_SUCCESS,
  GET_KNOWLEDGE_BASE_SUCCESS,
  POST_COPY_KNOWLEDGE_BASE_SUCCESS,
  GET_XLEXECUTION_PLAN_DETAILS_SUCCESS,
  POST_STRATEGY_FUNCTIONS_SUCCESS,
  POST_STRATEGY_GOAL_SUCCESS,
  GET_CHAT_DETAILS_INFO,
  // GET_DEPARTMENTS_SUCCESS,
  GET_EMPLOYEE_DATA_SUCCESS,
  GET_TASKS_PRIORITY_STATUS_SUCCESS,
  GET_INITIATIVE_TRACKER_DETAILS_SUCCESS,
  GET_EXISTING_GOALS_SUCCESS,
  GET_EXISTING_FUNCTIONS_SUCCESS,
  GET_TASK_DATA_SUCCESS,
  POST_TASK_COMMENTS_SUCCESS,
  POST_FUNGOAL_DEPENDECIES_SUCCESS,
  POST_FUNGOAL_KEYDECISIONS_SUCCESS,
  POST_FUNGOAL_RISK_ISSUES_SUCCESS,
  GET_FUNCTIONAL_GOAL_DETAILS_SUCCESS,
  GET_EXECUTION_PLAN_DETAILS_SUCCESS,
  GET_FUNGOAL_MEETING_REVIEW_DATA_SUCCESS,
  POST_FUNGOAL_MEETING_REVIEW_DATA_SUCCESS,
  POST_FUNGOALREVIEW_MEETING_PROGRESS_SUCCESS,
  POST_FUNGOALREVIEW_MEETING_NEXTSTEPS_SUCCESS,
  GET_EXECUTION_READINESS_FUNCTION,
  GET_DASHBOARD_STATE_DETAILS_SUCCESS,
  POST_DASHBOARD_STATE_DETAILS_SUCCESS,
  GET_EXECUTION_READINESS_FUNCTIONALAREA,
  GET_EXECUTION_READINESS_FUNCTION_SUCCESS,
  GET_STRATEGY_READINESS_FUNCTION_SUCCESS,
  POST_STRATEGY_READINESS_FUNCTION_SUCCESS,
  GET_CALENDAR_MEETING_DETAILS_SUCCESS,
  GET_DASHBOARD_TASK_DETAILS_SUCCESS,
  GET_ECOSYSTEM_MAPPING_SECTION_DETAILS_SUCCESS,
  POST_ECOSYSTEM_MAPPING_SECTION_DETAILS_SUCCESS,
  GET_NOTIFICATION_DETAILS_SUCCESS,
  POST_EXECUTION_READINESS_FUNCTIONALAREA_SUCCESS,
  GET_EXECUTION_READINESS_FUNCTIONALAREA_DATA_SUCCESS,
  POST_NOTIFICATION_DETAILS_SUCCESS,
  GET_FILE_MANAGER_DETAILS_SUCCESS,
  POST_FILE_MANAGER_DETAILS_SUCCESS,
  SET_SELECTED_NEW_BI,
  GET_ANSOFF_MATRIX_GRID_DETAILS_SUCCESS,
  POST_ANSOFF_MATRIX_DETAILS_SUCCESS,
  POST_EXECUTION_READINESS_DETAILS_SUCCESS,
  GET_EXECUTION_READINESS_REVIEW_DETAILS_SUCCESS,
  POST_EXECUTION_READINESS_CUSTOMER_INPUT_DETAILS_SUCCESS,
  POST_EXECUTION_READINESS_CUSTOMER_ANSWER_CHOICE_DETAILS_SUCCESS,
  SET_SELECTED_HELP_INFO_SIDEBAR,
  GET_GLOBAL_FILE_MANAGER_DETAILS_SUCCESS,
  POST_GLOBAL_FILE_MANAGER_DETAILS_SUCCESS,
  POST_NEW_STRATEGY_READINESS_FUNCTION_DETAILS_SUCCESS,
  GET_STRATEGY_READINESS_EXISTED_FUNCTION_DETAILS_SUCCESS,
  GET_STRATEGY_READINESS_FUNCTIONALAREA_DATA_SUCCESS,
  POST_STRATEGY_READINESS_FUNCTIONALAREA_SUCCESS,
  GET_STRATEGY_READINESS_FUNCTIONALAREA,
  POST_STRATEGY_READINESS_DETAILS_SUCCESS,
  GET_STRATEGY_READINESS_REVIEW_DETAILS_SUCCESS,
  POST_STRATEGY_READINESS_CUSTOMER_INPUT_DETAILS_SUCCESS,
  POST_STRATEGY_READINESS_CUSTOMER_ANSWER_CHOICE_DETAILS_SUCCESS,
  SET_SELECTED_PERMISSION,
  SET_SELECTED_SUB_MENU_PERMISSION,
  POST_FOLDER_MANAGER_ADD_NEW_SUCCESS,
  POST_FODER_MANAGER_EDIT_SUCCESS,
  POST_FOLDER_MANAGER_DELETE_SUCCESS,
  POST_UPLOAD_FILE_IN_SPECIFIC_FOLDER_SUCCESS,
  GET_DRIVE_FILES_SUCCESS,
  GET_PROJECT_SUMMARY_DETAILS_SUCCESS,
  POST_LOCAL_FOLDER_MANAGER_SUCCESS,
  GET_LOCAL_FOLDER_MANAGER_SUCCESS,
  POST_LOCAL_FOLDER_MANAGER_FILE_DELETE_SUCCESS,
  GET_SCREEN_COMMENTS_SUCCESS,
  POST_SCREEN_COMMENTS_SUCCESS,
  SET_TEMPLATE_BTN_SELECTED,
  UPDATE_USER_PASSWORD_SUCCESS,
  FORGOT_USER_PASSWORD_SUCCESS,
  GET_BI_FGOAL_STATUS_SUCCESS,
  POST_BI_FGOAL_STATUS_SUCCESS,
  GET_GLOBAL_SERACH_FILTER_SUCCESS,
  GET_IS_MAINTENANCE_MODE_SUCCESS,
  IS_MAINTENANCE_MODE,
  POST_XLSTRATEGYPLAN_DETAILS_SUCCESS,
  GET_XLSTRATEGYPLAN_DETAILS_SUCCESS,
  GET_EXPORT_WORBOARD_CSV_FILE_SUCCESS,
  LOGOUT_SUCCESS,
  PERMISSION_API_ERROR,
} from "../actions/actionTypes";

const initialState = {
  formSubmitted: false,
  userDetails: [],
  isLoggedin: false,
  isUpdatePasswordFetched: false,
  isUIListFetched: false,
  isBIFetched: false,
  isBISubmitted: false,
  isBIGetFetched: false,
  isSessionLive: false,
  isBISectorFetched: false,
  isBISectorSubmitted: false,
  isBISubSectorFetched: false,
  isBISubSectorSubmitted: false,
  isVCFetched: false,
  isAddCustomerVCSelection: false,
  isSessionAPIError: false,
  isPermissionAPIError: false,
  isSessionExpiry: false,
  showLoading: false,
  isMetricSelected: false,
  isMSDocsPosted: false,
  isMSDocsPostDone: false,
  isMeetingDataFetched: false,
  selectedBI: null,
  meetingData: [],
  selectedMeeting: null,
  chatActionInfo: null,
  isGoalDataFetched: false,
  isFunctionDataFetched: false,
  selectedHelpInfoSidebar: false,
  selectedHelpInfo: null,
  selectedPermission: "",
  isPostDeleteSuccess: false,
  isgetDriveFilesFetched: false,
  isMSDocsPostFetched: false,
  selectedTemplateBTN: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_START:
      return {
        ...state,
        showLoading: true,
      };
    case SESSION_EXPIRE_ERROR:
      return {
        ...state,
        isSessionExpiry: true,
        showLoading: false,
      };
    case SESSION_API_ERROR:
      return {
        ...state,
        apiError: action.apiError,
        showLoading: false,
        isSessionAPIError: true,
        isInternalServerError: false,
      };
    case PERMISSION_API_ERROR:
      return {
        ...state,
        permissionError: action.response,
        showLoading: false,
        isSessionAPIError: false,
        isPermissionAPIError: true,
        isInternalServerError: false,
      };
    case EXCEPTION_ERROR:
      return {
        ...state,
        ISError: action.ISError,
        showLoading: false,
        isInternalServerError: true,
      };
    case RESET_EXCEPTION_ERROR:
      return {
        ...state,
        ISError: action.ISError,
        showLoading: false,
        isInternalServerError: false,
        isSessionAPIError: false,
      };
    case LOGIN_SUCCESS:
      localStorage.clear();
      localStorage.setItem("userDetails", JSON.stringify(action.userDetails));
      return {
        ...state,
        userDetails: action.userDetails,
        submitted: action.submitted,
        isLoginFetched: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        fetchUserLogout: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case UPDATE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        fetchuserUpdatePasswordDetails: action.response,
        isUpdatePasswordFetched: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case FORGOT_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        fetchuserForgotPasswordDetails: action.response,
        isForgotPasswordFetched: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_ORG_DETAILS_SUCCESS:
      return {
        ...state,
        orgDetailsData: action.orgDetailsData,
        isOrgDetailsFetched: true,
        isLoginFetched: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case BI_DETAILS_LIST_SUCCESS:
      return {
        ...state,
        biDetails: action.biDetails,
        isBIFetched: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case UI_MENU_LIST_SUCCESS:
      return {
        ...state,
        uiListDetails: action.uiListDetails,
        isUIListFetched: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case BI_DETAILS_GET_SUCCESS:
      return {
        ...state,
        biDetailsGetSuccess: action.biDetailsGetSuccess,
        isBIGetFetched: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case BI_DETAILS_SUCCESS:
      localStorage.setItem("biDetails", JSON.stringify(action.biDetails));
      return {
        ...state,
        biDetails: action.biDetails,
        isBIFetched: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case BI_DETAILS_POST_SUCCESS:
      return {
        ...state,
        biDetailsPostSucess: action.bidetailsPost,
        isBISubmitted: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };

    case BI_SECTOR_GET_SUCCESS:
      return {
        ...state,
        biSectorGetDetails: action.biSectorGet,
        isBISectorFetched: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case BI_SECTOR_POST_SUCCESS:
      return {
        ...state,
        biSectorPost: action.biSectorPost,
        isBISectorSubmitted: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case BI_SUB_SECTOR_GET_SUCCESS:
      return {
        ...state,
        biSubSectorGetDetails: action.biSubSectorGet,
        isBISubSectorFetched: true,
        isSectorChanged: action.sectorChange,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case BI_SUB_SECTOR_POST_SUCCESS:
      return {
        ...state,
        biSubSectorPostDetails: action.biSubSectorPost,
        isBISubSectorSubmitted: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case BI_BUSINESS_TYPE_GET_SUCCESS:
      return {
        ...state,
        biBusinessTypeGetDetails: action.biBusinessTypeGet,
        isBIBusinessTypeFetched: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case BI_BUSINESS_TYPE_POST_SUCCESS:
      return {
        ...state,
        biBusinessTypePostDetails: action.biBusinessTypePost,
        isBIBusinessTypeSubmitted: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case BI_PRODUCT_DETAILS_GET_SUCCESS:
      return {
        ...state,
        biProductDetailsGetDetails: action.biProductDetailsGet,
        isBIProductDetailsFetched: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case BI_PRODUCT_DETAILS_POST_SUCCESS:
      return {
        ...state,
        biProductDetailsPostDetails: action.biProductDetailsPost,
        isBIProductDetailsSubmitted: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case VC_LIST_GET_SUCCESS:
      return {
        ...state,
        vcListGetData: action.vcListGetData,
        isVCFetchedAfterAdd: action.isVCFetchedAfterAdd,
        isVCFetched: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case VC_SELECTED_GET_SUCCESS:
      return {
        ...state,
        vcSelectedGetData: action.vcSelectedGetData,
        vcChanged: action.vcChanged,
        isVCDataFetched: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case VC_CREATE_GET_SUCCESS:
      return {
        ...state,
        vcCreateGetData: action.vcCreateGetData,
        vcAdded: action.vcAdded,
        isVCFetchedAfterAdd: action.isVCFetchedAfterAdd,
        isGetVCAdded: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case VC_CHANGE_TABLE_DATA_SUCCESS:
      return {
        ...state,
        vcAddTableData: action.vcAddTableData,
        GetVCStateChanged: action.vcStateChanged,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case ADD_CUSTOMER_VC_SELECTION_SUCCESS:
      return {
        ...state,
        addCustomerVCSelectionData: action.addCustomerVCSelectionData,
        isAddCustomerVCSelection: true,
        isVCEdited: action.vcEdited,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case REMOVE_CUSTOMER_VC_SELECTION_SUCCESS:
      return {
        ...state,
        removeCustomerVCSelectionData: action.removeCustomerVCSelectionData,
        isremoveCustomerVCSelection: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_CUSTOMER_VC_SELECTION_SUCCESS:
      return {
        ...state,
        getCustomerVCSelectionData: action.getCustomerVCSelectionData,
        isGetCustomerVCSelection: true,
        isInitialVCFetched: action.isInitialVCFetched,
        isUpdatedVCFetched: action.isUpdatedVCFetched,
        isVCFetched: action.vcFetched,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        getAddedCategoryData: action.getAddedCategoryData,
        isAddCat: action.isAddCat,
        isAddedCategory: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case ADD_CURRENT_DOC_URL:
      return {
        ...state,
        template_url: action.template_url,
        isURLAdded: action.isURLAdded,
        isSessionLive: true,
        isSessionExpiry: false,
        isInternalServerError: false,
        // showLoading: false,
      };
    case ADD_SCREEN_ID:
      return {
        ...state,
        screen_id: action.screen_id,
        isScreenIDAdded: action.isScreenIDAdded,
        isSessionLive: true,
        isSessionExpiry: false,
        isInternalServerError: false,
        // showLoading: false,
      };
    case ADD_MAIN_SCREEN_ID:
      return {
        ...state,
        main_screen_id: action.main_screen_id,
        isMainScreenIDAdded: action.isMainScreenIDAdded,
        isSessionLive: true,
        isSessionExpiry: false,
        isInternalServerError: false,
        // showLoading: false,
      };
    case GET_PRODUCTS_DETAILS_SUCCESS:
      return {
        ...state,
        getBIProductsData: action.getBIProductsData,
        isProductDetailsFetched: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_ANSOFF_MATRIX_DETAILS_SUCCESS:
      return {
        ...state,
        getAnsoffMatrixData: action.getAnsoffMatrixData,
        isAnsoffMatrixDetailsFetched: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_MARKET_SIZE_DETAILS_SUCCESS:
      return {
        ...state,
        getMarketSizeData: action.getMarketSizeData,
        isMarketSizeDetailsFetched: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_MARKET_SIZE_DETAILS_SUCCESS:
      return {
        ...state,
        postedMarketSizeData: action.postedMarketSizeData,
        isMarketSizeDetailsPosted: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_COMPETITOR_DETAILS_SUCCESS:
      return {
        ...state,
        getCompetitorData: action.getCompetitorData,
        isCompetitorDetailsFetched: true,
        isCompetitorUpdated: action.isCompetitorUpdated,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_COMPETITOR_DETAILS_SUCCESS:
      return {
        ...state,
        postedCompetitorData: action.postedCompetitorData,
        isCompetitorDetailsAdded: action.isCompetitorDetailsAdded,
        isCompetitorDetailsSaved: action.isCompetitorDetailsSaved,
        isCompetitorAdded: action.isCompetitorAdded,
        isCompetitorSaved: action.isCompetitorSaved,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_FETAURE_TYPE_DETAILS_SUCCESS:
      return {
        ...state,
        getFeatureTypeData: action.getFeatureTypeData,
        isFeatureTypeDetailsFetched: true,
        isFeatureDetailsFeched: action.isFeatureDetailsFeched,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_FETAURE_DETAILS_SUCCESS:
      return {
        ...state,
        postedFeatureData: action.postedFeatureData,
        isFeatureDetailsPosted: true,
        isFeaturePosted: action.isFeaturePosted,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
        featureID: action.featureID,
      };
    case GET_COMPETITOR_ANALYSIS_DETAILS_SUCCESS:
      return {
        ...state,
        getCompAnalData: action.getCompAnalData,
        isCompAnalDetailsFetched: true,
        isCompAnalFetched: action.isCompAnalFetched,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_COMPETITOR_ANALYSIS_DETAILS_SUCCESS:
      return {
        ...state,
        postedCompAnalData: action.postedCompAnalData,
        isCompAnalDetailsPosted: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_METRICS_SUCCESS:
      return {
        ...state,
        getMetricData: action.getMetricData,
        isMetricsFetched: true,
        isMetricUpdateFetched: action.isMetricsFeched,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_METRICS_SUCCESS:
      return {
        ...state,
        postedMetricData: action.postedMetricData,
        isMetricsSaved: action.isMetricsSaved,
        isMetricSelected: action.isMetricSelected,
        isMetricsPosted: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_MS_DOCS_SUCCESS:
      return {
        ...state,
        postedMSDocs: action.response,
        isMSDocsPosted: action.isMSDocsPosted,
        screen_name: action.screen_name,
        isMSDocsPostFetched: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_MS_DOCS_SUCCESS:
      return {
        ...state,
        getMSDocsdata: action.response,
        isMSDocsPostDone: action.isMSDocsPostDone,
        getScreenName: action.getScreenName,
        isMSDocsDataFetched: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_NOTES_SUCCESS:
      return {
        ...state,
        postedNotesData: action.response,
        isPostActionChanged: action.isActionChanged,
        isNotesPostFetched: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_NOTES_SUCCESS:
      return {
        ...state,
        getNotesData: action.response,
        isGetActionChanged: action.isActionChanged,
        isNotesDataFetched: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_MEETING_SUCCESS:
      return {
        ...state,
        postedMeetingData: action.response,
        isMeetingPostFetched: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_MEETING_SUCCESS:
      return {
        ...state,
        getMeetingData: action.response,
        isMeetingDataFetched: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case "SET_SELECTED_BI":
      return {
        ...state,
        selectedBI: action.payload,
      };
    case "SET_MEETING_DATA":
      return {
        ...state,
        meetingData: action.payload,
      };
    case "SET_SELECTED_MEETING":
      return {
        ...state,
        selectedMeeting: action.payload,
      };
    case GET_KNOWLEDGE_BASE_SUCCESS:
      return {
        ...state,
        // getKnowledgeBaseData: action.response.template_data[0],
        getKnowledgeBaseData: action.response,
        isKnowledgeBaseDataFetched: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_COPY_KNOWLEDGE_BASE_SUCCESS:
      return {
        ...state,
        postedCopyKnowledgeBaseTemplate: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_XLEXECUTION_PLAN_DETAILS_SUCCESS:
      return {
        ...state,
        strategyPlanData: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_STRATEGY_GOAL_SUCCESS:
      return {
        ...state,
        postGoalDetails: action.response,
        isGoalDataFetched: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_STRATEGY_FUNCTIONS_SUCCESS:
      return {
        ...state,
        postStrategyFunctionDetails: action.response,
        isFunctionDataFetched: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    // case GET_DEPARTMENTS_SUCCESS:
    //   return {
    //     ...state,
    //     getDepartmentDetails: action.response,
    //     isSessionLive: true,
    //     isSessionExpiry: false,
    //     isSessionAPIError: false,
    //     isInternalServerError: false,
    //     showLoading: false,
    //   };
    case GET_EMPLOYEE_DATA_SUCCESS:
      return {
        ...state,
        getEmployeeDetails: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_TASKS_PRIORITY_STATUS_SUCCESS:
      return {
        ...state,
        getMasterTasksPriorityAndStatusDetails: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_INITIATIVE_TRACKER_DETAILS_SUCCESS:
      return {
        ...state,
        getInitTrackerDetails: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_CHAT_DETAILS_INFO:
      return {
        ...state,
        chatActionInfo: action.chatActionInfo,
      };
    case GET_EXISTING_GOALS_SUCCESS:
      return {
        ...state,
        getExistingGoalsData: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_EXISTING_FUNCTIONS_SUCCESS:
      return {
        ...state,
        getExistingFunctionsData: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_TASK_DATA_SUCCESS:
      return {
        ...state,
        getSingleTaskDetails: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_TASK_COMMENTS_SUCCESS:
      return {
        ...state,
        postTaskCommentsData: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_FUNGOAL_DEPENDECIES_SUCCESS:
      return {
        ...state,
        fetchpostFungoalDependencies: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_FUNGOAL_KEYDECISIONS_SUCCESS:
      return {
        ...state,
        fetchposFungoaltKeyDecisions: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_FUNGOAL_RISK_ISSUES_SUCCESS:
      return {
        ...state,
        fetchpostFungoalRiskIssues: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_FUNCTIONAL_GOAL_DETAILS_SUCCESS:
      return {
        ...state,
        getFunctionalGoalDetails: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_FUNGOAL_MEETING_REVIEW_DATA_SUCCESS:
      return {
        ...state,
        fetchFunGoalMeetingReviewData: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_FUNGOAL_MEETING_REVIEW_DATA_SUCCESS:
      return {
        ...state,
        fecthPostFunGoalMeetingReviewResponse: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_FUNGOALREVIEW_MEETING_PROGRESS_SUCCESS:
      return {
        ...state,
        fecthPostFunGoalMeetingProgressResponse: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_FUNGOALREVIEW_MEETING_NEXTSTEPS_SUCCESS:
      return {
        ...state,
        fecthPostFunGoalMeetingNextStepsResponse: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_DASHBOARD_STATE_DETAILS_SUCCESS:
      return {
        ...state,
        fetchDashboardStateData: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_DASHBOARD_STATE_DETAILS_SUCCESS:
      return {
        ...state,
        fetchDashboardStateDataResponse: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_EXECUTION_READINESS_FUNCTIONALAREA:
      return {
        ...state,
        allQuestionsList: action.allQuestionsList,
      };
    case GET_EXECUTION_READINESS_FUNCTION_SUCCESS:
      return {
        ...state,
        fetchExecutionReadinessFunctionData: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_STRATEGY_READINESS_FUNCTION_SUCCESS:
      return {
        ...state,
        fetchStrategyReadinessFunctionData: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_STRATEGY_READINESS_FUNCTION_SUCCESS:
      return {
        ...state,
        fetchPostStrategyReadinessFunctionData: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_CALENDAR_MEETING_DETAILS_SUCCESS:
      return {
        ...state,
        fetchCalendarMeetingData: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_DASHBOARD_TASK_DETAILS_SUCCESS:
      return {
        ...state,
        fetchDashboardTaskData: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_ECOSYSTEM_MAPPING_SECTION_DETAILS_SUCCESS:
      return {
        ...state,
        fetchEcosystemMappingSectionData: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_ECOSYSTEM_MAPPING_SECTION_DETAILS_SUCCESS:
      return {
        ...state,
        fetchPostEcosystemMappingSectionData: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_EXECUTION_READINESS_FUNCTIONALAREA_DATA_SUCCESS:
      return {
        ...state,
        fetchExecutionReadinessFunAreaData: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_EXECUTION_READINESS_FUNCTIONALAREA_SUCCESS:
      return {
        ...state,
        fetchPostExecutionReadinessFunAreaData: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_NOTIFICATION_DETAILS_SUCCESS:
      return {
        ...state,
        fecthNotifData: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_NOTIFICATION_DETAILS_SUCCESS:
      return {
        ...state,
        fecthPostNotifData: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_FILE_MANAGER_DETAILS_SUCCESS:
      return {
        ...state,
        fecthNotifData: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_FILE_MANAGER_DETAILS_SUCCESS:
      return {
        ...state,
        fecthPostNotifData: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case SET_SELECTED_NEW_BI:
      return { ...state, selectedNewBI: action.value };

    case GET_ANSOFF_MATRIX_GRID_DETAILS_SUCCESS:
      return {
        ...state,
        fetchAnsoffMatrixGridDetails: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_ANSOFF_MATRIX_DETAILS_SUCCESS:
      return {
        ...state,
        fetchpostAnsoffMatrixDetails: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_EXECUTION_READINESS_DETAILS_SUCCESS:
      return {
        ...state,
        fetchpostExecutionReadinessDetails: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_EXECUTION_READINESS_REVIEW_DETAILS_SUCCESS:
      return {
        ...state,
        fetchExecutionReadinessReviewDetails: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_EXECUTION_READINESS_CUSTOMER_INPUT_DETAILS_SUCCESS:
      return {
        ...state,
        fetchpostExecutionReadinessCustomerInputDetails: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_EXECUTION_READINESS_CUSTOMER_ANSWER_CHOICE_DETAILS_SUCCESS:
      return {
        ...state,
        fetchpostExecutionReadinessCustomerAnswerChoiceDetails: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_NEW_STRATEGY_READINESS_FUNCTION_DETAILS_SUCCESS:
      return {
        ...state,
        fetchPostNewStrategyReadinessFunctionData: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_STRATEGY_READINESS_EXISTED_FUNCTION_DETAILS_SUCCESS:
      return {
        ...state,
        fetchStrategyReadinessExistedFunctionData: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };

    case GET_STRATEGY_READINESS_FUNCTIONALAREA:
      return {
        ...state,
        allStrategyQuestionsList: action.allStrategyQuestionsList,
      };

    // case POST_STRATEGY_READINESS_FUNCTIONALAREA_SUCCESS:
    //   return {
    //     ...state,
    //     fetchPostStrategyReadinessFunAreaData: action.response,
    //     isSessionLive: true,
    //     isSessionExpiry: false,
    //     isSessionAPIError: false,
    //     isInternalServerError: false,
    //     showLoading: false,
    //   };
    case GET_STRATEGY_READINESS_FUNCTIONALAREA_DATA_SUCCESS:
      return {
        ...state,
        fetchStrategyReadinessFunAreaData: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_STRATEGY_READINESS_DETAILS_SUCCESS:
      return {
        ...state,
        fetchpostStrategyReadinessDetails: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_STRATEGY_READINESS_REVIEW_DETAILS_SUCCESS:
      return {
        ...state,
        fetchStrategyReadinessReviewDetails: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };

    case POST_STRATEGY_READINESS_CUSTOMER_INPUT_DETAILS_SUCCESS:
      return {
        ...state,
        fetchpostStrategyReadinessCustomerInputDetails: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };

    case POST_STRATEGY_READINESS_CUSTOMER_ANSWER_CHOICE_DETAILS_SUCCESS:
      return {
        ...state,
        fetchpostStrategyReadinessCustomerAnswerChoiceDetails: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case SET_SELECTED_HELP_INFO_SIDEBAR:
      return {
        ...state,
        selectedHelpInfoSidebar: action.value,
        selectedHelpInfo: action.payload,
      };
    case SET_SELECTED_PERMISSION:
      return { ...state, selectedPermission: action.value };
    case SET_SELECTED_SUB_MENU_PERMISSION:
      return { ...state, selectedSubMenuPermission: action.value };
    case POST_FOLDER_MANAGER_ADD_NEW_SUCCESS:
      return {
        ...state,
        fetchPostFolderManagerAddNew: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_FODER_MANAGER_EDIT_SUCCESS:
      return {
        ...state,
        fetchPostFolderManagerEdit: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_FOLDER_MANAGER_DELETE_SUCCESS:
      return {
        ...state,
        fetchPostFolderManagerDelete: action.response,
        isPostDeleteSuccess: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_UPLOAD_FILE_IN_SPECIFIC_FOLDER_SUCCESS:
      return {
        ...state,
        fetchPostUploadFileInSpecificFolder: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_DRIVE_FILES_SUCCESS:
      return {
        ...state,
        getDriveFilesData: action.response,
        isgetDriveFilesFetched: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_PROJECT_SUMMARY_DETAILS_SUCCESS:
      return {
        ...state,
        fetchProjectSummaryDetails: action.response,
        isgetPrjectSummaryDetailsFetched: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_LOCAL_FOLDER_MANAGER_SUCCESS:
      return {
        ...state,
        fetchpostLocalManagerData: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_LOCAL_FOLDER_MANAGER_SUCCESS:
      return {
        ...state,
        fetchLocalManagerData: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_LOCAL_FOLDER_MANAGER_FILE_DELETE_SUCCESS:
      return {
        ...state,
        fetchpostLocalFolderManagerFileDelete: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_SCREEN_COMMENTS_SUCCESS:
      return {
        ...state,
        fetchGetScreenCommentsData: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_SCREEN_COMMENTS_SUCCESS:
      return {
        ...state,
        fetchPostScreenCommentsData: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case SET_TEMPLATE_BTN_SELECTED:
      return { ...state, selectedTemplateBTN: action.value };
    case GET_BI_FGOAL_STATUS_SUCCESS:
      return {
        ...state,
        fetchGetBiFStatusData: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_BI_FGOAL_STATUS_SUCCESS:
      return {
        ...state,
        fetchpostBiFStatusData: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_GLOBAL_SERACH_FILTER_SUCCESS:
      return {
        ...state,
        fetchGetGlobalSearchFilterData: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_IS_MAINTENANCE_MODE_SUCCESS:
      return {
        ...state,
        fetchGetMaintenanceMode: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case IS_MAINTENANCE_MODE:
      return { ...state, isMaintenanceMode: action.value };
    case GET_XLSTRATEGYPLAN_DETAILS_SUCCESS:
      return {
        ...state,
        fetchgetXLStrategyPlanData: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_XLSTRATEGYPLAN_DETAILS_SUCCESS:
      return {
        ...state,
        fetchpostXLStrategyPlanData: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_EXPORT_WORBOARD_CSV_FILE_SUCCESS:
      return {
        ...state,
        fetchgetExportWorkboardCsvFile: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
