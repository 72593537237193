import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainComponent from "../MainComponent";
import Select from "react-select";
import "./MarketSize.css";
import {
  getMarketSizeDetails,
  postMarketSizeDetails,
} from "../../store/actions";
import { useNavigate } from "react-router-dom";
import Alert from "../Alert";
import eventEmitter from "../../store/emitter/emitter";
import HelpInfo from "../../utils/helpInfoProviderComponent.js";
import keys from "../../utils/helpInfoKeys.json";
import UploadFiles from "../UploadFiles.js";
import CommentSection from "../../utils/CommentSection.js";
import Login from "../Login.js";
import SideNavHelpInfo from "../../utils/SideNavHelpInfo.js";
import { motion } from "motion/react";

function MarketSize() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [selectedUnit, setSelectedUnit] = useState({});
  const [marketSize, setMarketSize] = useState(null);
  const isMarketSizeDetailsFetched = useSelector(
    (state) => state.isMarketSizeDetailsFetched
  );
  const getMarketSizeData = useSelector((state) => state.getMarketSizeData);
  const isMarketSizeDetailsPosted = useSelector(
    (state) => state.isMarketSizeDetailsPosted
  );
  const postedMarketSizeData = useSelector(
    (state) => state.postedMarketSizeData
  );
  const [currencyData, setCurencyData] = useState([]);
  const [UnitData, setUnitData] = useState([]);
  const bi_id = localStorage.getItem("bi_id");
  const product_id = localStorage.getItem("product_id");
  const [currentDocURL, setCurrentDocURL] = useState("");
  const [isNotFirstTime, setIsNotFirstTime] = useState(false);
  const [isMarketDataChanged, setIsMarketDataChanged] = useState(false);
  const [initialMarketSize, setInitialMarketSize] = useState(null);
  const [initialCurrency, setInitialCurrency] = useState(null);
  const [initialUnit, setInitialUnit] = useState(null);
  const [remarks, setRemarks] = useState("");
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const popoverRef = useRef(null);
  const selectedPermission = useSelector((state) => state.selectedPermission);
  const [uploadProgress, setUploadProgress] = useState(0);
  const selectedHelpInfoSidebar = useSelector(
    (state) => state.selectedHelpInfoSidebar
  );
  useEffect(() => {
    eventEmitter.on("UPLOAD_PROGRESS", (percent) => {
      setUploadProgress(percent);
    });

    return function cleanup() {
      setUploadProgress(0);
      eventEmitter.off("UPLOAD_PROGRESS");
    };
  }, []);

  // useEffect(() => {
  //   if (isMainScreenIDAdded) {
  //     dispatch(
  //       getMSDocs(
  //         localStorage.getItem("bi_id"),
  //         localStorage.getItem("screen_id"),
  //         localStorage.getItem("main_screen_id")
  //       )
  //     );
  //   }
  // }, [isMainScreenIDAdded]);

  useEffect(() => {
    if (bi_id && product_id) {
      dispatch(getMarketSizeDetails(bi_id, product_id));
    }
  }, []);
  useEffect(() => {
    if (isMarketSizeDetailsFetched) {
      if (getMarketSizeData) {
        if (getMarketSizeData.ok) {
          if (getMarketSizeData.data) {
            if (getMarketSizeData.data.market_size) {
              setIsNotFirstTime(true);
              setInitialMarketSize(
                getMarketSizeData.data.market_size.customer_market_size
              );
              setMarketSize(
                getMarketSizeData.data.market_size.customer_market_size
              );
            }
            setUnitData(getMarketSizeData.data.currency_size);
            getMarketSizeData.data.currency_size.map((obj, i) => {
              if (obj.isactive) {
                setInitialUnit(obj);
                setSelectedUnit(obj);
              }
            });
            setRemarks(getMarketSizeData.data.market_size.remarks);
            setCurencyData(getMarketSizeData.data.currency_unit);
            getMarketSizeData.data.currency_unit.map((obj, i) => {
              if (obj.isactive) {
                setInitialCurrency(obj);
                setSelectedCurrency(obj);
              }
            });
          }
        }
      }
    }
  }, [isMarketSizeDetailsFetched]);

  useEffect(() => {
    if (isMarketSizeDetailsPosted) {
      if (postedMarketSizeData) {
        if (postedMarketSizeData.ok) {
          if (postedMarketSizeData.data) {
            // navigate("/competitors", { replace: true });
            window.location.reload();
          }
        }
      }
    }
  }, [isMarketSizeDetailsPosted]);

  const handleUnitSelect = (e) => {
    if (isNotFirstTime && initialUnit?.value != e.value) {
      setIsMarketDataChanged(true);
    } else {
      setIsMarketDataChanged(false);
    }
    setSelectedUnit(e);
  };
  const handleCurrencySelect = (e) => {
    if (isNotFirstTime && initialCurrency?.value != e.value) {
      setIsMarketDataChanged(true);
    } else {
      setIsMarketDataChanged(false);
    }
    setSelectedCurrency(e);
  };
  const handleMarketSizeChange = (e) => {
    if (
      isNotFirstTime &&
      (initialMarketSize > e.target.value || initialMarketSize < e.target.value)
    ) {
      setIsMarketDataChanged(true);
    } else {
      setIsMarketDataChanged(false);
    }
    setMarketSize(e.target.value);
  };
  const handleRemarksChange = (e) => {
    setRemarks(e.target.value);
  };
  const handleClick = () => {
    if (selectedPermission === "write" || selectedPermission === "admin") {
      if (localStorage.getItem("bi_id") && localStorage.getItem("product_id")) {
        if (marketSize == "") {
          return Alert.warning({
            title: "Warning",
            html: "Please enter Market Size",
            icon: "warning",
          });
        } else if (!selectedUnit.value) {
          return Alert.warning({
            title: "Warning",
            html: "Please select Unit",
            icon: "warning",
          });
        } else if (!selectedCurrency.value) {
          return Alert.warning({
            title: "Warning",
            html: "Please select Currency",
            icon: "warning",
          });
        } else {
          if (isMarketDataChanged && remarks == "") {
            return Alert.warning({
              title: "Warning",
              html: "Please enter Remarks",
              icon: "warning",
            });
          } else {
            dispatch(
              postMarketSizeDetails(
                bi_id,
                product_id,
                marketSize,
                selectedUnit.value,
                selectedCurrency.value,
                remarks
              )
            );
            navigate("/competitors", { replace: true });
          }
        }
      } else {
        return Alert.warning({
          title: "Warning",
          html: "Please select Business Intiative",
          icon: "warning",
        });
      }
    } else {
      navigate("/competitors", { replace: true });
    }
  };

  const handleClickOutside = (event) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target)) {
      setIsPopoverOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleBack = () => {
    navigate("/dashboard", { replace: true });
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      height: 36,
      borderRadius: 10,
      width: "100%",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#ecf4f3" : "white",
      color: "black",
    }),
    menu: (provided) => ({
      ...provided,
      width: 220,
    }),
  };
  const customFilter = (option, searchText) => {
    if (option.data.label.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };
  const content = (
    <motion.div
      initial={{ y: "100%" }}
      animate={{ y: 0 }}
      transition={{ duration: 0.8, ease: "easeInOut" }}
      style={{ width: "100%", overflow: "auto" }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            fontSize: "var(--main-heading)",
            fontWeight: "var(--heading-font-weight)",
          }}
        >
          What's the estimated market size of the product?
        </div>
      </div>
      <br />
      <div
        style={{ display: "flex", justifyContent: "space-between" }}
        className="market-size-input-container"
      >
        <div className="market-size-input-sub-section">
          <div>
            <label
              style={{
                fontSize: "var(--sub-heading-font-size)",
                fontWeight: "var(--heading-font-weight)",
              }}
            >
              Market Size
            </label>
          </div>
          <input
            style={{ fontSize: "var(--text-font-size)" }}
            className="market-size-input"
            type="number"
            value={marketSize}
            onChange={(e) => handleMarketSizeChange(e)}
            disabled={
              selectedPermission === "write" || selectedPermission === "admin"
                ? false
                : true
            }
          />
        </div>
        <div className="market-size-input-sub-section">
          <div>
            <label
              style={{
                fontSize: "var(--sub-heading-font-size)",
                fontWeight: "var(--heading-font-weight)",
              }}
            >
              Unit
            </label>
          </div>
          <Select
            required
            name="currency"
            styles={customStyles}
            className="form-control select-unit"
            placeholder={
              <span style={{ fontSize: "var(--text-font-size)" }}>
                Select Unit
              </span>
            }
            value={selectedUnit}
            options={UnitData}
            onChange={handleUnitSelect}
            isSearchable={true}
            filterOption={customFilter}
            autosize={true}
            getOptionLabel={(e) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <span
                  style={{ marginLeft: 5, fontSize: "var(--text-font-size)" }}
                >
                  {e.label}
                </span>
              </div>
            )}
            components={{
              //   DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
              // Option: IconOption,
            }}
            isDisabled={
              selectedPermission === "write" || selectedPermission === "admin"
                ? false
                : true
            }
          />
        </div>
        <div className="market-size-input-sub-section">
          <div>
            <label
              style={{
                fontSize: "var(--sub-heading-font-size)",
                fontWeight: "var(--heading-font-weight)",
              }}
            >
              Currency
            </label>
          </div>
          <Select
            required
            name="currency"
            styles={customStyles}
            className="form-control select-currency"
            placeholder={
              <span style={{ fontSize: "var(--text-font-size)" }}>
                Select Currency
              </span>
            }
            value={selectedCurrency}
            options={currencyData}
            onChange={handleCurrencySelect}
            isSearchable={true}
            filterOption={customFilter}
            getOptionLabel={(e) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <span
                  style={{ marginLeft: 5, fontSize: "var(--text-font-size)" }}
                >
                  {e.label}
                </span>
              </div>
            )}
            components={{
              //   DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
              // Option: IconOption,
            }}
            isDisabled={
              selectedPermission === "write" || selectedPermission === "admin"
                ? false
                : true
            }
          />
        </div>
      </div>
      <div>
        <div>
          <label
            style={{
              fontSize: "var(--sub-heading-font-size)",
              fontWeight: "var(--heading-font-weight)",
            }}
          >
            Remarks
          </label>
        </div>
        <div className="market-size-textarea-container">
          <textarea
            style={{ fontSize: "var(--text-font-size)" }}
            className="form-control"
            rows={3}
            value={remarks}
            onChange={(e) => handleRemarksChange(e)}
            disabled={
              selectedPermission === "write" || selectedPermission === "admin"
                ? false
                : true
            }
          />
        </div>
        <div>
          <label
            style={{
              fontSize: "var(--sub-heading-font-size)",
              fontWeight: "var(--heading-font-weight)",
            }}
          >
            Please upload market size related documents
          </label>
        </div>
      </div>
      <div className="marketsize-upload-files">
        <UploadFiles
          selectedSubMenuPermission={selectedPermission}
          product_id={localStorage.getItem("product_id")}
        />
      </div>
      <CommentSection />
      {selectedHelpInfoSidebar && <SideNavHelpInfo />}
    </motion.div>
  );
  const rcontent = (
    <div style={{ height: "100%", width: "100%" }}>
      {currentDocURL && (
        <>
          <a
            style={{
              float: "right",
              marginBottom: 5,
              textDecoration: "none",
              border: 0,
              padding: 5,
              borderRadius: 10,
              background: "#0A5F59",
              color: "white",
              paddingLeft: 20,
              paddingRight: 20,
            }}
            target="_blank"
            href={currentDocURL}
          >
            Popout
          </a>
          <iframe height="100%" width="100%" src={currentDocURL} />
        </>
      )}
    </div>
  );
  if (!localStorage.getItem("authenticated")) {
    return (
      <div>
        <Login />
      </div>
    );
  } else {
    return (
      <MainComponent
        maincontent={content}
        showSelector={true}
        showButtons={true}
        showTemplate={false}
        handleNext={handleClick}
        handleBackButton={handleBack}
        showProducts={true}
        breadcrumbParent={"Strategy Validation"}
        breadcrumbChild={"Market Size"}
        documentName={"Market Size"}
        helpKey={keys["Market Size"].Name}
        image={"question"}
      />
    );
  }
}

export default MarketSize;
