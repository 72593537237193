import React, { useEffect, useState } from "react";
import MainComponent from "../MainComponent";
import "./ExecutionPlan.css";
import Select from "react-select";
import Collaborators from "../../assets/images/collaborators.svg";
import NewTabIcon from "../../assets/images/new-tab-icon.svg";
import Reddot from "../../assets/images/reddot.svg";
import Bluedot from "../../assets/images/bluedot.svg";
import Greendot from "../../assets/images/greendot.svg";
import Yellowdot from "../../assets/images/yellowdot.svg";
import Graydot from "../../assets/images/graydot.svg";
import Cancel from "../../assets/images/Cancel.svg";
import Add from "../../assets/images/add.svg";
import ProfileAdd from "../../assets/images/profile-add.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import DeleteIcon from "../../assets/images/trash.svg";
import { AiOutlineCaretRight, AiOutlineClose } from "react-icons/ai";
import {
  ArrowUpRight,
  ArrowDownLeft,
  ChevronRight,
} from "react-bootstrap-icons";
import ConstraintsPopUp from "./ConstraintsPopUp";
import KeyDecisionsPopUp from "./KeyDecisionsPopUp";
import RiskAndIssuesPopUp from "./RiskAndIssuesPopUp";
import { useDispatch, useSelector } from "react-redux";
import {
  getBiFGoalStatus,
  getEmployeeData,
  getFunctionalGoalReviewMeetingData,
  getFunctionalGoals,
  getMasterTaskPriorityAndStatusData,
  postBiFGoalStatus,
} from "../../store/actions";
import { getRandomColor, createImageFromInitials } from "../Utils";
import { createStatus } from "./WorkboardProgress";
import InputList from "./InputList";
import NotesIcon from "../../assets/images/notes.svg";
import CalendarIcon from "../../assets/images/Calendar.svg";
import { useNavigate } from "react-router-dom";
import FunctionalGoalMeeting from "./FunctionalGoalMeeting";
import Login from "../Login";
import { motion } from "motion/react";

function ExecutionPlan() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [milestoneData, setMilestoneData] = useState([]);
  const [tasksData, setTasksData] = useState([]);
  const [dependencyData, setDependencyData] = useState([]);
  const [keyDecisionData, setKeyDecisionData] = useState([]);
  const [riskIssuesData, setRiskIssuesData] = useState([]);
  const [collaborators, setCollaborators] = useState([]);
  const [functionalGoalName, setFunctionalGoalName] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const getEmployeeDetails = useSelector((state) => state.getEmployeeDetails);
  const [isTaskSidebarOpen, setIsTaskSidebarOpen] = useState(false);
  const [constraintsDisplay, setConstraintsDisplay] = useState(false);
  const [keyDecisionsDisplay, setkeyDecisionsDisplay] = useState(false);
  const [riskAndIssuesDisplay, setRiskAndIssuesDisplay] = useState(false);
  const [sidebarData, setSidebarData] = useState(null);
  const [showMeetingPopup, setShowMeetingPopup] = useState(false);
  const [progressData, setProgressData] = useState([]);
  const [nextSteps, setNextSteps] = useState([]);
  const [priorityData, setPriorityData] = useState([]);
  const [bi_owner, setBiOwner] = useState("");
  const [showCollaboratorPopup, setShowCollaboratorPopup] = useState(false);
  const selectedSubMenuPermission = useSelector(
    (state) => state.selectedSubMenuPermission
  );
  const [expand, setExpand] = useState(null);
  const getFunctionalGoalDetails = useSelector(
    (state) => state.getFunctionalGoalDetails
  );
  const getMasterTasksPriorityAndStatusDetails = useSelector(
    (state) => state.getMasterTasksPriorityAndStatusDetails
  );
  const customIcons = [
    { value: "Off track", label: <ArrowDownLeft color="#EF2C2C" /> },
    { value: "At risk", label: <ArrowDownLeft color="#F09642" /> },
    { value: "On track", label: <ArrowUpRight color="#0DA14B" /> },
    { value: "Completed", label: <ArrowUpRight color="#51A5F2" /> },
    { value: "On hold", label: <img alt="graydot" src={Graydot} /> },
  ];
  const initialSelectedIcons = Array(customIcons.length).fill(null);
  const [selectedIcons, setSelectedIcons] = useState(initialSelectedIcons);

  const handleSelectChange = (selectedOption, index) => {
    const updatedSelectedIcons = {
      ...selectedIcons,
      [dynamicData[index].title]: selectedOption,
    };
    setSelectedIcons(updatedSelectedIcons);
    const data = {
      title: dynamicData[index].title,
      value: selectedOption.value,
    };
    dispatch(
      postBiFGoalStatus(
        localStorage.getItem("bi_id"),
        localStorage.getItem("product_id"),
        localStorage.getItem("functional_goal_id"),
        data
      )
    );
  };

  const fetchpostFungoalDependencies = useSelector(
    (state) => state.fetchpostFungoalDependencies
  );
  const fetchposFungoaltKeyDecisions = useSelector(
    (state) => state.fetchposFungoaltKeyDecisions
  );
  const fetchpostFungoalRiskIssues = useSelector(
    (state) => state.fetchpostFungoalRiskIssues
  );
  const fetchGetBiFStatusData = useSelector(
    (state) => state.fetchGetBiFStatusData
  );
  const getCustomLabel = (value) => {
    const icon = customIcons.find((icon) => icon.value === value);
    return icon ? icon.label : null;
  };
  useEffect(() => {
    if (fetchGetBiFStatusData && fetchGetBiFStatusData.data) {
      const initialSelectedIcons = fetchGetBiFStatusData.data.reduce(
        (acc, item) => {
          acc[item.title] = {
            value: item.value,
            label: getCustomLabel(item.value),
          };
          return acc;
        },
        {}
      );
      setSelectedIcons(initialSelectedIcons);
    }
  }, [fetchGetBiFStatusData]);

  const openTaskNav = (value) => {
    if (value == "constraints") {
      setIsTaskSidebarOpen(true);
      setConstraintsDisplay(true);
    } else if (value == "keyDecisions") {
      setIsTaskSidebarOpen(true);
      setkeyDecisionsDisplay(true);
    } else if (value == "riskAndIssues") {
      setIsTaskSidebarOpen(true);
      setRiskAndIssuesDisplay(true);
    }
  };
  const showSideBar = (value, data) => {
    if (value == "constraints") {
      setIsTaskSidebarOpen(true);
      setConstraintsDisplay(true);
      setIsEdit(true);
      setSidebarData(data);
    } else if (value == "keyDecisions") {
      setIsTaskSidebarOpen(true);
      setkeyDecisionsDisplay(true);
      setIsEdit(true);
      setSidebarData(data);
    } else if (value == "riskAndIssues") {
      setIsTaskSidebarOpen(true);
      setRiskAndIssuesDisplay(true);
      setIsEdit(true);
      setSidebarData(data);
    }
  };

  const dynamicData = [
    { title: "Overall" },
    { title: "Scope" },
    { title: "Schedule" },
    { title: "Cost" },
    { title: "Resources" },
  ];
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      border: "none",
      minHeight: "10px",
      marginTop: "2px",
    }),
  };
  const [empData, setEmpData] = useState(null);

  useEffect(() => {
    if (getMasterTasksPriorityAndStatusDetails) {
      const transformedPriorityOptions =
        getMasterTasksPriorityAndStatusDetails.priority_dt.map((priority) => ({
          value: priority,
          label: priority,
        }));
      setPriorityData(transformedPriorityOptions);
    }
  }, [getMasterTasksPriorityAndStatusDetails]);

  useEffect(() => {
    dispatch(
      getBiFGoalStatus(
        localStorage.getItem("bi_id"),
        localStorage.getItem("product_id"),
        localStorage.getItem("functional_goal_id")
      )
    );
  }, []);
  useEffect(() => {
    dispatch(getEmployeeData(localStorage.getItem("bi_id")));
    dispatch(getMasterTaskPriorityAndStatusData());
    dispatch(
      getFunctionalGoals(
        localStorage.getItem("bi_id"),
        localStorage.getItem("product_id"),
        localStorage.getItem("functional_goal_id"),
        localStorage.getItem("goal_review_id")
      )
    );
  }, []);

  useEffect(() => {
    if (getFunctionalGoalDetails) {
      setFunctionalGoalName(getFunctionalGoalDetails.functional_goal[0].value);
      setMilestoneData(getFunctionalGoalDetails.milestone_dt);
      setTasksData(getFunctionalGoalDetails.tasks_data);
      setDependencyData(getFunctionalGoalDetails.dependenc_data);
      setKeyDecisionData(getFunctionalGoalDetails.key_data);
      setRiskIssuesData(getFunctionalGoalDetails.risk_data);
      setProgressData(getFunctionalGoalDetails.prg_data);
      setNextSteps(getFunctionalGoalDetails.next_data);
      setBiOwner(getFunctionalGoalDetails.bi_owner[0]);
      setCollaborators(getFunctionalGoalDetails.collaborators);
    }
  }, [getFunctionalGoalDetails]);

  useEffect(() => {
    if (
      getEmployeeDetails &&
      getEmployeeDetails.emp_data &&
      getEmployeeDetails.emp_data.length > 0
    ) {
      setEmpData(
        getEmployeeDetails.emp_data.map((obj) => {
          return {
            value: obj.id,
            label: obj.prop_name,
            icon: createImageFromInitials(
              30,
              obj.prop_name,
              getRandomColor(obj.prop_name)
            ),
          };
        })
      );
    }
  }, [getEmployeeDetails]);

  useEffect(() => {
    if (fetchpostFungoalDependencies) {
      if (fetchpostFungoalDependencies.action === "add") {
        setDependencyData([
          ...dependencyData,
          {
            key: fetchpostFungoalDependencies.key,
            value: fetchpostFungoalDependencies.desc,
            title: fetchpostFungoalDependencies.value,
            status: fetchpostFungoalDependencies.dep_status,
            due_date: fetchpostFungoalDependencies.date,
            date_time: fetchpostFungoalDependencies.date_time,
          },
        ]);
      } else if (fetchpostFungoalDependencies.action === "edit") {
        const updatedItems = dependencyData.map((item) => {
          if (item.key === fetchpostFungoalDependencies.key) {
            return {
              ...item,
              key: fetchpostFungoalDependencies.key,
              value: fetchpostFungoalDependencies.desc,
              title: fetchpostFungoalDependencies.value,
              status: fetchpostFungoalDependencies.dep_status,
              due_date: fetchpostFungoalDependencies.date,
              date_time: fetchpostFungoalDependencies.date_time,
            };
          }
          return item;
        });

        setDependencyData(updatedItems);
      }
    }
  }, [fetchpostFungoalDependencies]);

  useEffect(() => {
    if (fetchposFungoaltKeyDecisions) {
      if (fetchposFungoaltKeyDecisions.action === "add") {
        setKeyDecisionData([
          ...keyDecisionData,
          {
            key: fetchposFungoaltKeyDecisions.key,
            value: fetchposFungoaltKeyDecisions.desc,
            title: fetchposFungoaltKeyDecisions.value,
            assigned_to: fetchposFungoaltKeyDecisions.assigned_to,
            due_date: fetchposFungoaltKeyDecisions.date,
            date_time: fetchposFungoaltKeyDecisions.date_time,
          },
        ]);
      } else if (fetchposFungoaltKeyDecisions.action === "edit") {
        const updatedItems = keyDecisionData.map((item) => {
          if (item.key === fetchposFungoaltKeyDecisions.key) {
            return {
              ...item,
              key: fetchposFungoaltKeyDecisions.key,
              value: fetchposFungoaltKeyDecisions.desc,
              title: fetchposFungoaltKeyDecisions.value,
              assigned_to: fetchposFungoaltKeyDecisions.assigned_to,
              due_date: fetchposFungoaltKeyDecisions.date,
              date_time: fetchposFungoaltKeyDecisions.date_time,
            };
          }
          return item;
        });
        setKeyDecisionData(updatedItems);
      }
    }
  }, [fetchposFungoaltKeyDecisions]);

  useEffect(() => {
    if (fetchpostFungoalRiskIssues) {
      if (fetchpostFungoalRiskIssues.action === "add") {
        setRiskIssuesData([
          ...riskIssuesData,
          {
            key: fetchpostFungoalRiskIssues.key,
            value: fetchpostFungoalRiskIssues.desc,
            title: fetchpostFungoalRiskIssues.value,
            assigned_to: fetchpostFungoalRiskIssues.assigned_to,
            due_date: fetchpostFungoalRiskIssues.date,
            mitigation: fetchpostFungoalRiskIssues.mitigation,
            rating: fetchpostFungoalRiskIssues.rating,
            date_time: fetchpostFungoalRiskIssues.date_time,
          },
        ]);
      } else if (fetchpostFungoalRiskIssues.action === "edit") {
        const updatedItems = riskIssuesData.map((item) => {
          if (item.key === fetchpostFungoalRiskIssues.key) {
            return {
              ...item,
              key: fetchpostFungoalRiskIssues.key,
              value: fetchpostFungoalRiskIssues.desc,
              title: fetchpostFungoalRiskIssues.value,
              assigned_to: fetchpostFungoalRiskIssues.assigned_to,
              due_date: fetchpostFungoalRiskIssues.date,
              mitigation: fetchpostFungoalRiskIssues.mitigation,
              rating: fetchpostFungoalRiskIssues.rating,
              date_time: fetchpostFungoalRiskIssues.date_time,
            };
          }
          return item;
        });
        setRiskIssuesData(updatedItems);
      }
    }
  }, [fetchpostFungoalRiskIssues]);

  const handleNotesClick = () => {
    // localStorage.setItem("note_id", postedMeetingData.note_id);
    const newTab = window.open("/notes", "_blank");
    newTab.focus();
    // window.location.reload();
  };

  const handleMeetingClick = (e) => {
    dispatch(
      getFunctionalGoalReviewMeetingData(
        localStorage.getItem("functional_goal_id")
      )
    );
    setShowMeetingPopup(true);
  };

  const handleCloseMeetingPopup = () => {
    setShowMeetingPopup(false);
  };
  const handleCloseButtonClick = () => {
    navigate("/bitracker", { replace: true });
  };
  const handleShowCollaborators = () => {
    setShowCollaboratorPopup(true);
  };
  const content = (
    <motion.div
      initial={{ y: "100%" }}
      animate={{ y: 0 }}
      transition={{ duration: 0.8, ease: "easeInOut" }}
    >
      {showMeetingPopup && (
        <FunctionalGoalMeeting
          onClose={handleCloseMeetingPopup}
          selectedSubMenuPermission={selectedSubMenuPermission}
        />
      )}
      <div className="Main-header" style={{ marginTop: "-40px" }}>
        <h4
          style={{
            fontSize: "var(--main-heading)",
            fontWeight: "var(--heading-font-weight)",
          }}
        >
          {functionalGoalName}
        </h4>

        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="xl-lead">
            <span style={{ marginRight: "10px" }}>BI Owner:</span>
            <span>
              {bi_owner ? (
                <img
                  alt="owner"
                  src={createImageFromInitials(
                    30,
                    bi_owner,
                    getRandomColor(bi_owner)
                  )}
                />
              ) : (
                <img alt="" src={ProfileAdd} />
              )}
            </span>
          </div>
          <button
            className="meeting-button"
            type="button"
            onClick={() => handleMeetingClick()}
          >
            <img alt="meeting" src={CalendarIcon} />
            {/* <span style={{ color: "white", paddingLeft: "5px" }}>Meeting</span> */}
          </button>
          <button
            className="share-button"
            type="button"
            onClick={handleShowCollaborators}
          >
            <img alt="people" src={Collaborators} />
          </button>
          <button
            className="close-button"
            type="button"
            onClick={() => handleCloseButtonClick()}
          >
            <img src={Cancel} width={36} height={36} />
          </button>
        </div>
      </div>
      {/* first container */}
      <div className="Primary-container">
        <div className="column-one">
          <div className="sub-column-one" style={{ overflowY: "auto" }}>
            <table>
              <thead className="container-head">
                <tr>
                  <th
                    style={{
                      opacity: "1",
                      fontWeight: "bold",
                      paddingLeft: "10px",
                      textAlign: "left",
                      width: "fit-content",
                    }}
                  >
                    Progress
                  </th>
                  <th
                    style={{
                      textAlign: "left",
                      fontSize: "12px",
                      color: "#646464",
                    }}
                  >
                    Includes accomplishments this period and any route to green
                  </th>
                  <th>
                    <img
                      alt="newtabicon"
                      src={NewTabIcon}
                      style={{
                        opacity: ".5",
                        cursor: "pointer",
                      }}
                      onClick={() => setExpand("progress")}
                    />
                  </th>
                </tr>
              </thead>
              <tbody border="none">
                <tr style={{ border: "none" }}>
                  <td
                    colSpan={3}
                    style={{
                      textAlign: "left",
                      paddingLeft: "20px",
                    }}
                  >
                    <InputList type="progress" data={progressData} />
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="sub-column-two" style={{ overflowY: "auto" }}>
            <table>
              <thead className="container-head">
                <tr>
                  <th
                    style={{
                      opacity: "1",
                      fontWeight: "bold",
                      paddingLeft: "10px",
                      textAlign: "left",
                      width: "fit-content",
                    }}
                  >
                    Next Steps
                  </th>
                  <th
                    style={{
                      textAlign: "left",
                      fontSize: "12px",
                      color: "#646464",
                      width: "70%",
                    }}
                  >
                    Includes key activities in next 2 weeks
                  </th>
                  <th>
                    <img
                      alt="newtabicon"
                      src={NewTabIcon}
                      style={{
                        opacity: ".5",
                        cursor: "pointer",
                      }}
                      onClick={() => setExpand("nextsteps")}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ border: "none" }}>
                  <td
                    colSpan={3}
                    style={{
                      textAlign: "left",
                      paddingLeft: "20px",
                    }}
                  >
                    <InputList type="nextsteps" data={nextSteps} />
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="column-two" style={{ overflowY: "auto" }}>
          <table>
            <thead className="container-head">
              <tr>
                <th
                  style={{
                    opacity: "1",
                    fontWeight: "bold",
                    paddingLeft: "10px",
                    textAlign: "left",
                  }}
                >
                  Milestones
                </th>
                <th>Date</th>
                <th>Status</th>
                <th>
                  <span style={{ marginRight: "10px" }}>
                    <img
                      alt="newtabicon"
                      src={NewTabIcon}
                      style={{
                        opacity: ".5",
                        cursor: "pointer",
                      }}
                      onClick={() => setExpand("milestones")}
                    />
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {milestoneData &&
                milestoneData.length > 0 &&
                milestoneData.map((milestone_dt) => (
                  <tr key={milestone_dt.id} style={{ border: "none" }}>
                    <td
                      style={{
                        textAlign: "left",
                        paddingLeft: "10px",
                        width: "65%",
                      }}
                    >
                      {milestone_dt.name}
                    </td>
                    <td>{milestone_dt.exp_end_date}</td>
                    <td>{createStatus(milestone_dt.status)}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="column-three">
          <div className="container-head">
            <span className="title">Status</span>
          </div>
          <div className="sub-column-one">
            {dynamicData.map((item, index) => (
              <div className="data" key={index}>
                <span className="sub-title">{item.title}</span>
                <Select
                  isDisabled={
                    selectedSubMenuPermission === "write" ||
                    selectedSubMenuPermission === "admin"
                      ? false
                      : true
                  }
                  value={selectedIcons[item.title]}
                  onChange={(selectedOption) =>
                    handleSelectChange(selectedOption, index)
                  }
                  options={customIcons}
                  isSearchable={false}
                  styles={customStyles}
                  placeholder=""
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  menuZIndex={100}
                />
              </div>
            ))}
          </div>
          {/* <div className="sub-column-two"></div> */}
          <div className="sub-column-three">
            <div className="data">
              <img alt="reddot" src={Reddot} />
              <span className="sub-title">Off track</span>
            </div>

            <div
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <div className="data">
                <img alt="yellowdot" src={Yellowdot} />
                <span className="sub-title">At risk</span>
              </div>
              <div className="data">
                <img alt="greendot" src={Greendot} />
                <span className="sub-title">On track</span>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="data">
                <img alt="bluedot" src={Bluedot} />
                <span className="sub-title">Completed</span>
              </div>
              <div className="data">
                <img alt="graydot" src={Graydot} />
                <span className="sub-title">On hold</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* second container */}
      <div className="secondary-container">
        <div className="column-one" style={{ overflowY: "auto" }}>
          <table>
            <thead className="container-head">
              <tr>
                <th style={{ opacity: "1" }}>
                  <span className="title">Completed & Upcoming Tasks</span>
                </th>
                <th>Owner</th>
                <th>Due date</th>
                <th>Status</th>
                <th>
                  <span style={{ marginRight: "10px" }}>
                    <img
                      alt="newtabicon"
                      src={NewTabIcon}
                      style={{
                        opacity: ".5",
                        cursor: "pointer",
                      }}
                      onClick={() => setExpand("completedAndUpcomingTasks")}
                    />
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {tasksData &&
                tasksData.length > 0 &&
                tasksData.map((tasks_data, milestoneIndex) => (
                  <tr key={tasks_data.id} style={{ border: "none" }}>
                    <td
                      style={{
                        textAlign: "left",
                        paddingLeft: "10px",
                        width: "65%",
                      }}
                    >
                      {tasks_data.name}
                    </td>
                    <td>
                      {tasks_data.assigned_to &&
                        tasks_data.assigned_to.find((j) => j.is_active) && (
                          <img
                            alt="owner"
                            src={createImageFromInitials(
                              30,
                              tasks_data.assigned_to.find((j) => j.is_active)
                                .label,
                              getRandomColor(
                                tasks_data.assigned_to.find((j) => j.is_active)
                                  .label
                              )
                            )}
                          />
                        )}
                    </td>
                    <td>{tasks_data.exp_end_date}</td>
                    <td>{tasks_data.status}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="column-two">
          <div className="sub-column-one" style={{ overflowY: "auto" }}>
            <table>
              <thead className="container-head">
                <tr>
                  <th style={{ opacity: "1" }}>
                    <span className="title">
                      Constraints & Dependencies
                      <button
                        disabled={
                          selectedSubMenuPermission === "write" ||
                          selectedSubMenuPermission === "admin"
                            ? false
                            : true
                        }
                        type="button"
                        onClick={() => openTaskNav("constraints")}
                      >
                        <img alt="add" src={Add} width={12} height={12} />
                        <span style={{ fontSize: "10px", paddingLeft: "5px" }}>
                          NEW
                        </span>
                      </button>
                      {constraintsDisplay && (
                        <ConstraintsPopUp
                          isTaskSidebarOpen={isTaskSidebarOpen}
                          setIsTaskSidebarOpen={(value) =>
                            setIsTaskSidebarOpen(value)
                          }
                          setConstraintsDisplay={(value) =>
                            setConstraintsDisplay(value)
                          }
                          isEdit={isEdit}
                          setIsEdit={(value) => setIsEdit(value)}
                          sidebarData={sidebarData}
                          setDependencyData={(value) =>
                            setDependencyData(value)
                          }
                          dependencyData={dependencyData}
                        />
                      )}
                    </span>
                  </th>
                  <th></th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>
                    <span>
                      <img
                        alt="newtabicon"
                        src={NewTabIcon}
                        style={{
                          opacity: ".5",
                          cursor: "pointer",
                        }}
                        onClick={() => setExpand("constraints")}
                      />
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {dependencyData &&
                  dependencyData.length > 0 &&
                  dependencyData.map((dependency_data) => (
                    <tr key={dependency_data.key} style={{ border: "none" }}>
                      <td
                        style={{
                          textAlign: "left",
                          paddingLeft: "10px",
                          width: "60%",
                        }}
                      >
                        {dependency_data.title}
                      </td>

                      <td style={{ paddingRight: "10px" }}>
                        <button
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                          }}
                          onClick={(e) =>
                            showSideBar("constraints", dependency_data)
                          }
                        >
                          <ChevronRight />
                        </button>
                      </td>
                      <td>{dependency_data.due_date}</td>
                      <td>{dependency_data.status}</td>
                    </tr>
                  ))}
              </tbody>
            </table>

            {/* </span> */}
          </div>
          <div className="sub-column-two" style={{ overflowY: "auto" }}>
            <table>
              <thead className="container-head">
                <tr>
                  <th style={{ opacity: "1" }}>
                    <span className="title">
                      Key Decisions
                      <button
                        disabled={
                          selectedSubMenuPermission === "write" ||
                          selectedSubMenuPermission === "admin"
                            ? false
                            : true
                        }
                        type="button"
                        onClick={(e) => openTaskNav("keyDecisions")}
                      >
                        <img alt="add" src={Add} width={12} height={12} />
                        <span style={{ fontSize: "10px", paddingLeft: "5px" }}>
                          NEW
                        </span>
                      </button>
                      {keyDecisionsDisplay && (
                        <KeyDecisionsPopUp
                          isTaskSidebarOpen={isTaskSidebarOpen}
                          setIsTaskSidebarOpen={(value) =>
                            setIsTaskSidebarOpen(value)
                          }
                          setkeyDecisionsDisplay={(value) =>
                            setkeyDecisionsDisplay(value)
                          }
                          isEdit={isEdit}
                          setIsEdit={(value) => setIsEdit(value)}
                          dependencyData={dependencyData}
                          empData={empData}
                          sidebarData={sidebarData}
                          setKeyDecisionData={(value) =>
                            setKeyDecisionData(value)
                          }
                          keyDecisionData={keyDecisionData}
                        />
                      )}
                    </span>
                  </th>
                  <th></th>
                  <th style={{ textAlign: "left" }}>Who</th>
                  <th>When</th>
                  <th>
                    <img
                      alt="newtabicon"
                      src={NewTabIcon}
                      style={{
                        opacity: ".5",
                        cursor: "pointer",
                      }}
                      onClick={() => setExpand("keyDecisions")}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {keyDecisionData &&
                  keyDecisionData.length > 0 &&
                  keyDecisionData.map((key_data) => (
                    <tr key={key_data.key} style={{ border: "none" }}>
                      <td style={{ textAlign: "left", paddingLeft: "10px" }}>
                        {key_data.title}
                      </td>
                      <td>
                        <button
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                          }}
                          onClick={(e) => showSideBar("keyDecisions", key_data)}
                        >
                          <ChevronRight />
                        </button>
                      </td>
                      <td style={{ display: "flex" }}>
                        {key_data.assigned_to.length > 0 &&
                          key_data.assigned_to.map(
                            (owner) =>
                              owner.is_active && (
                                <div style={{ display: "flex" }}>
                                  <img
                                    style={{ marginLeft: "-8px" }}
                                    alt="owner"
                                    src={createImageFromInitials(
                                      30,
                                      owner.label,
                                      getRandomColor(owner.label)
                                    )}
                                  />
                                </div>
                              )
                          )}
                      </td>
                      <td>{key_data.due_date}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* third container */}
      <div className="third-container" style={{ overflowY: "auto" }}>
        <div
          className="column-one"
          style={{ overflowY: "auto", overflowX: "hidden" }}
        >
          <table>
            <thead className="container-head">
              <tr>
                <th style={{ opacity: "1" }}>
                  <span className="title">
                    Risk & Issues
                    <button
                      disabled={
                        selectedSubMenuPermission === "write" ||
                        selectedSubMenuPermission === "admin"
                          ? false
                          : true
                      }
                      type="button"
                      onClick={() => openTaskNav("riskAndIssues")}
                    >
                      <img alt="add" src={Add} width={12} height={12} />
                      <span style={{ fontSize: "10px", paddingLeft: "5px" }}>
                        NEW
                      </span>
                    </button>
                    {riskAndIssuesDisplay && (
                      <RiskAndIssuesPopUp
                        empData={empData}
                        ratingData={priorityData}
                        isTaskSidebarOpen={isTaskSidebarOpen}
                        setIsTaskSidebarOpen={(value) =>
                          setIsTaskSidebarOpen(value)
                        }
                        setRiskAndIssuesDisplay={(value) =>
                          setRiskAndIssuesDisplay(value)
                        }
                        isEdit={isEdit}
                        setIsEdit={(value) => setIsEdit(value)}
                        sidebarData={sidebarData}
                        setRiskIssuesData={(value) => setRiskIssuesData(value)}
                        riskIssuesData={riskIssuesData}
                      />
                    )}
                  </span>
                </th>
                <th></th>
                <th> Mitigation/Response</th>
                <th>Rating</th>
                <th> Tgt. Closure</th>
                <th>Owner</th>
                <th>
                  <span>
                    <img
                      alt="newtabicon"
                      src={NewTabIcon}
                      style={{
                        opacity: ".5",
                        cursor: "pointer",
                      }}
                      onClick={() => setExpand("riskAndIssues")}
                    />
                  </span>
                </th>
              </tr>
            </thead>
            <tbody style={{ fontWeight: "normal" }}>
              {riskIssuesData &&
                riskIssuesData.length > 0 &&
                riskIssuesData.map((risk_data) => (
                  <tr key={risk_data.key} style={{ border: "none" }}>
                    <td
                      style={{
                        textAlign: "left",
                        paddingLeft: "10px",
                        width: "50%",
                      }}
                    >
                      {risk_data.title}
                    </td>
                    <td>
                      <button
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                        }}
                        onClick={(e) => showSideBar("riskAndIssues", risk_data)}
                      >
                        <ChevronRight />
                      </button>
                    </td>
                    <td>{risk_data.mitigation}</td>
                    <td>{risk_data.rating}</td>
                    <td>{risk_data.due_date}</td>
                    <td style={{ display: "flex", justifyContent: "center" }}>
                      {risk_data.assigned_to.map(
                        (owner) =>
                          owner.is_active && (
                            <div style={{ display: "flex" }}>
                              <img
                                style={{ marginLeft: "-8px" }}
                                alt="owner"
                                src={createImageFromInitials(
                                  30,
                                  owner.label,
                                  getRandomColor(owner.label)
                                )}
                              />
                            </div>
                          )
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      {expand === "progress" && (
        <Expand
          setExpand={(value) => setExpand(value)}
          expand={expand}
          progressData={progressData}
          functionalGoalName={functionalGoalName}
        />
      )}
      {expand === "nextsteps" && (
        <Expand
          setExpand={(value) => setExpand(value)}
          expand={expand}
          progressData={progressData}
          functionalGoalName={functionalGoalName}
        />
      )}
      {expand === "constraints" && (
        <Expand
          setExpand={(value) => setExpand(value)}
          expand={expand}
          dependencyData={dependencyData}
          openTaskNav={(value) => openTaskNav(value)}
          showSideBar={(value, data) => showSideBar(value, data)}
          functionalGoalName={functionalGoalName}
        />
      )}
      {expand === "keyDecisions" && (
        <Expand
          setExpand={(value) => setExpand(value)}
          expand={expand}
          keyDecisionData={keyDecisionData}
          openTaskNav={(value) => openTaskNav(value)}
          showSideBar={(value, data) => showSideBar(value, data)}
          functionalGoalName={functionalGoalName}
        />
      )}
      {expand === "riskAndIssues" && (
        <Expand
          setExpand={(value) => setExpand(value)}
          expand={expand}
          riskIssuesData={riskIssuesData}
          openTaskNav={(value) => openTaskNav(value)}
          showSideBar={(value, data) => showSideBar(value, data)}
          functionalGoalName={functionalGoalName}
        />
      )}
      {expand === "completedAndUpcomingTasks" && (
        <Expand
          setExpand={(value) => setExpand(value)}
          expand={expand}
          tasksData={tasksData}
          openTaskNav={(value) => openTaskNav(value)}
          showSideBar={(value, data) => showSideBar(value, data)}
          functionalGoalName={functionalGoalName}
        />
      )}
      {expand === "milestones" && (
        <Expand
          setExpand={(value) => setExpand(value)}
          expand={expand}
          milestoneData={milestoneData}
          openTaskNav={(value) => openTaskNav(value)}
          showSideBar={(value, data) => showSideBar(value, data)}
          functionalGoalName={functionalGoalName}
        />
      )}
      {showCollaboratorPopup && (
        <div className="modal-overlay">
          <div
            className="modal-content collaborator-popover"
            style={{
              padding: "20px",
              height: "60%",
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            <div
              className="modal-header"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h5
                style={{
                  paddingTop: "5px",
                  fontSize: "var(--main-heading)",
                  fontWeight: "var(--heading-font-weight)",
                }}
              >
                Meeting Participants
              </h5>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <AiOutlineClose
                  size={24}
                  onClick={() => setShowCollaboratorPopup(false)}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
            <hr style={{ borderTop: "1px solid" }} />
            <div className="modal-body" style={{ display: "flex" }}>
              <div style={{ height: "45.5%" }}>
                {collaborators &&
                  collaborators.map((item) => (
                    <div style={{ marginBottom: "10px" }}>
                      <img
                        style={{ marginRight: "5px" }}
                        alt=""
                        src={createImageFromInitials(
                          30,
                          item,
                          getRandomColor(item)
                        )}
                      />
                      <span>{item}</span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </motion.div>
  );
  if (!localStorage.getItem("authenticated")) {
    return (
      <div>
        <Login />
      </div>
    );
  } else {
    return (
      <>
        <MainComponent
          maincontent={content}
          // showTemplate={true}
          showSelector={true}
          showProducts={true}
          breadcrumbParent={"GTM Execution"}
          breadcrumbChild={"Initiative Tracker"}
          breadcrumbSubChild={"BI Review"}
          breadcrumbChildLink={"/bitracker"}
        />
      </>
    );
  }
}

export default ExecutionPlan;

export const Expand = ({
  setExpand,
  expand,
  progressData,
  dependencyData,
  tasksData,
  milestoneData,
  openTaskNav,
  showSideBar,
  keyDecisionData,
  riskIssuesData,
  functionalGoalName,
}) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const selectedSubMenuPermission = useSelector(
    (state) => state.selectedSubMenuPermission
  );
  const handleMenuClick = () => {
    setMenuOpen(!isMenuOpen);
  };
  const closeTaskNav = () => {
    setExpand(null);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getFunctionalGoals(
        localStorage.getItem("bi_id"),
        localStorage.getItem("product_id"),
        localStorage.getItem("functional_goal_id"),
        localStorage.getItem("goal_review_id")
      )
    );
  }, []);
  return (
    <>
      <div className={"progress-expand-container"}>
        <div className={"gtm-sidebar-header"}>
          <div
            style={{
              fontSize: "var(--main-heading)",
              fontWeight: "var(--heading-font-weight)",
            }}
          >
            {functionalGoalName}
          </div>
          <a href="#" className="closebtn" onClick={() => closeTaskNav()}>
            &times;
          </a>
        </div>
        {expand === "progress" && (
          <div
            style={{
              marginLeft: "10px",
              marginRight: "20px",
              overflowY: "auto",
            }}
          >
            <div
              style={{
                fontSize: "var(--sub-heading-font-size)",
                fontWeight: "var(--heading-font-weight)",
              }}
            >
              Progress
            </div>

            <InputList type="progress" data={progressData} />
          </div>
        )}
        {expand === "nextsteps" && (
          <div
            style={{
              marginLeft: "10px",
              marginRight: "20px",
              overflowY: "auto",
            }}
          >
            <div
              style={{
                fontSize: "var(--sub-heading-font-size)",
                fontWeight: "var(--heading-font-weight)",
              }}
            >
              Next Steps
            </div>

            <InputList type="nextsteps" data={progressData} />
          </div>
        )}
        {expand === "constraints" && (
          <div style={{ overflowY: "auto" }}>
            <table>
              <thead className="container-head">
                <tr>
                  <th style={{ opacity: "1" }}>
                    <span className="title">
                      Constraints & Dependencies
                      <button
                        disabled={
                          selectedSubMenuPermission === "write" ||
                          selectedSubMenuPermission === "admin"
                            ? false
                            : true
                        }
                        type="button"
                        onClick={() => openTaskNav("constraints")}
                      >
                        <img alt="add" src={Add} width={12} height={12} />
                        <span style={{ fontSize: "10px", paddingLeft: "5px" }}>
                          NEW
                        </span>
                      </button>
                    </span>
                  </th>
                  <th></th>
                  <th>Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {dependencyData &&
                  dependencyData.length > 0 &&
                  dependencyData.map((dependency_data) => (
                    <tr key={dependency_data.key} style={{ border: "none" }}>
                      <td
                        style={{
                          textAlign: "left",
                          paddingLeft: "10px",
                          width: "60%",
                        }}
                      >
                        {dependency_data.title}
                      </td>

                      <td style={{ paddingRight: "10px" }}>
                        <button
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                          }}
                          onClick={(e) =>
                            showSideBar("constraints", dependency_data)
                          }
                        >
                          <ChevronRight />
                        </button>
                      </td>
                      <td>{dependency_data.due_date}</td>
                      <td>{dependency_data.status}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
        {expand === "keyDecisions" && (
          <div style={{ overflowY: "auto" }}>
            <table>
              <thead className="container-head">
                <tr>
                  <th style={{ opacity: "1" }}>
                    <span className="title">
                      Key Decisions
                      <button
                        type="button"
                        onClick={(e) => openTaskNav("keyDecisions")}
                      >
                        <img alt="add" src={Add} width={12} height={12} />
                        <span style={{ fontSize: "10px", paddingLeft: "5px" }}>
                          NEW
                        </span>
                      </button>
                    </span>
                  </th>
                  <th></th>
                  <th style={{ textAlign: "left" }}>Who</th>
                  <th>When</th>
                </tr>
              </thead>
              <tbody>
                {keyDecisionData &&
                  keyDecisionData.length > 0 &&
                  keyDecisionData.map((key_data) => (
                    <tr key={key_data.key} style={{ border: "none" }}>
                      <td style={{ textAlign: "left", paddingLeft: "10px" }}>
                        {key_data.title}
                      </td>
                      <td>
                        <button
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                          }}
                          onClick={(e) => showSideBar("keyDecisions", key_data)}
                        >
                          <ChevronRight />
                        </button>
                      </td>
                      <td style={{ display: "flex" }}>
                        {key_data.assigned_to.length > 0 &&
                          key_data.assigned_to.map(
                            (owner) =>
                              owner.is_active && (
                                <div style={{ display: "flex" }}>
                                  <img
                                    style={{ marginLeft: "-8px" }}
                                    alt="owner"
                                    src={createImageFromInitials(
                                      30,
                                      owner.label,
                                      getRandomColor(owner.label)
                                    )}
                                  />
                                </div>
                              )
                          )}
                      </td>
                      <td>{key_data.due_date}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
        {expand === "riskAndIssues" && (
          <div style={{ overflowY: "auto" }}>
            <span
              className="title container-head"
              style={{ paddingLeft: "10px", justifyContent: "space-between" }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                Risk & Issues
                <button
                  type="button"
                  onClick={() => openTaskNav("riskAndIssues")}
                >
                  <img alt="add" src={Add} width={12} height={12} />
                  <span style={{ fontSize: "10px", paddingLeft: "5px" }}>
                    NEW
                  </span>
                </button>
              </div>
            </span>
            <table>
              <thead className="container-head">
                <tr>
                  <th style={{ textAlign: "left", paddingLeft: "10px" }}>
                    Description
                  </th>
                  <th></th>
                  <th> Mitigation/Response</th>
                  <th>Rating</th>
                  <th> Tgt. Closure</th>
                  <th>Owner</th>
                </tr>
              </thead>
              <tbody>
                {riskIssuesData &&
                  riskIssuesData.length > 0 &&
                  riskIssuesData.map((risk_data) => (
                    <tr key={risk_data.key} style={{ border: "none" }}>
                      <td style={{ textAlign: "left", paddingLeft: "10px" }}>
                        {risk_data.title}
                      </td>
                      <td>
                        <button
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                          }}
                          onClick={(e) =>
                            showSideBar("riskAndIssues", risk_data)
                          }
                        >
                          <ChevronRight />
                        </button>
                      </td>
                      <td>{risk_data.mitigation}</td>
                      <td>{risk_data.rating}</td>
                      <td>{risk_data.due_date}</td>
                      <td style={{ display: "flex", justifyContent: "center" }}>
                        {risk_data.assigned_to.map(
                          (owner) =>
                            owner.is_active && (
                              <div style={{ display: "flex" }}>
                                <img
                                  style={{ marginLeft: "-8px" }}
                                  alt="owner"
                                  src={createImageFromInitials(
                                    30,
                                    owner.label,
                                    getRandomColor(owner.label)
                                  )}
                                />
                              </div>
                            )
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
        {expand === "completedAndUpcomingTasks" && (
          <div style={{ overflowY: "auto" }}>
            <table>
              <thead className="container-head">
                <tr>
                  <th style={{ opacity: "1" }}>
                    <span className="title">Completed & Upcoming Tasks</span>
                  </th>
                  <th>Owner</th>
                  <th>Due date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {tasksData &&
                  tasksData.length > 0 &&
                  tasksData.map((tasks_data, milestoneIndex) => (
                    <tr key={tasks_data.id} style={{ border: "none" }}>
                      <td
                        style={{
                          textAlign: "left",
                          paddingLeft: "10px",
                          width: "65%",
                        }}
                      >
                        {tasks_data.name}
                      </td>
                      <td>
                        {tasks_data.assigned_to &&
                          tasks_data.assigned_to.find((j) => j.is_active) && (
                            <img
                              alt="owner"
                              src={createImageFromInitials(
                                30,
                                tasks_data.assigned_to.find((j) => j.is_active)
                                  .label,
                                getRandomColor(
                                  tasks_data.assigned_to.find(
                                    (j) => j.is_active
                                  ).label
                                )
                              )}
                            />
                          )}
                      </td>
                      <td>{tasks_data.exp_end_date}</td>
                      <td>{tasks_data.status}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
        {expand === "milestones" && (
          <table>
            <thead className="container-head">
              <tr>
                <th
                  style={{
                    opacity: "1",
                    fontWeight: "bold",
                    paddingLeft: "10px",
                    textAlign: "left",
                  }}
                >
                  Milestones
                </th>
                <th>Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {milestoneData &&
                milestoneData.length > 0 &&
                milestoneData.map((milestone_dt) => (
                  <tr key={milestone_dt.id} style={{ border: "none" }}>
                    <td
                      style={{
                        textAlign: "left",
                        paddingLeft: "10px",
                        width: "65%",
                      }}
                    >
                      {milestone_dt.name}
                    </td>
                    <td>{milestone_dt.exp_end_date}</td>
                    <td>{createStatus(milestone_dt.status)}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};
