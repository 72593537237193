import React, { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import { PiBellLight } from "react-icons/pi";
import { getRandomColor, createImageFromInitials } from "./Utils.js";
import { AiFillCloseCircle } from "react-icons/ai";
import { useDispatch } from "react-redux";
import {
  getNoticationsDetails,
  postNoticationsDetails,
} from "../store/actions/index.js";
import { useNavigate } from "react-router-dom";

const NotificationMenu = ({
  notifData,
  notificationRef,
  fecthPostNotifData,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [hoveredId, setHoveredId] = useState(null);

  const handleRemoveNotification = (id) => {
    if (id) {
      dispatch(postNoticationsDetails(id));
    }
  };

  useEffect(() => {
    if (fecthPostNotifData) {
      dispatch(getNoticationsDetails(false));
    }
  }, [fecthPostNotifData, dispatch]);

  function stripHtmlTags(str) {
    const div = document.createElement("div");
    div.innerHTML = str;
    return div.textContent || div.innerText || "";
  }
  const handleClearAllButtonClick = () => {
    dispatch(postNoticationsDetails("", "clearall"));
  };
  const handleNotificationClick = (type, bi_id, product_id, function_id) => {
    if (type === "Task") {
      localStorage.setItem("selectedfunction", function_id);
      localStorage.setItem("bi_id", bi_id);
      localStorage.setItem("product_id", product_id);
      navigate("/workboard");
      window.location.reload();
    }
  };
  return (
    <div
      className="notification-menu"
      style={{
        width: "400px",
        overflowX: "hidden",
        textAlign: "left",
        right: "60px",
        backgroundColor: "#ecf4f3",
        position: "absolute",
        zIndex: 9999,
        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
        borderRadius: "10px",
        top: "62px",
      }}
      ref={notificationRef}
    >
      <div className="notification-menu-clear-all-container">
        <span className="notification-title">Notifications</span>
        <button
          onClick={handleClearAllButtonClick}
          className="notification-clear-all"
        >
          Clear all
        </button>
      </div>
      <div>
        {notifData && notifData.length > 0 ? (
          notifData.map((notify, index) => (
            <div
              key={notify.id}
              style={{
                borderBottom:
                  index === notifData.length - 1 ? "none" : "1px solid #ccc",
                padding: "10px 10px 0px 10px",
                alignItems: "center",
              }}
            >
              <div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                  onMouseEnter={() => setHoveredId(notify.id)}
                  onMouseLeave={() => setHoveredId(null)}
                >
                  <button
                    style={{
                      fontSize: "var(--text-font-size)",
                      backgroundColor: "transparent",
                      border: "none",
                      textAlign: "left",
                      width: "95%",
                    }}
                    onClick={() =>
                      handleNotificationClick(
                        notify.document_type,
                        notify.bi_id,
                        notify.product_id,
                        notify.function_id
                      )
                    }
                  >
                    {stripHtmlTags(notify.title)}
                  </button>
                  <span style={{width:'5%'}}>
                    {hoveredId === notify.id && (
                      <AiFillCloseCircle
                        style={{ color: "rgb(10, 95, 89)", cursor: "pointer" }}
                        size={20}
                        onClick={() => handleRemoveNotification(notify.id)}
                      />
                    )}
                  </span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div style={{ padding: "20px", textAlign: "center" }}>
            No notifications
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationMenu;
