import React, { useState, useEffect } from "react";
import { Form, FormCheck } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { default as ReactSelect } from "react-select";
import { FaArrowRight } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { SlCalender } from "react-icons/sl";
import moment from "moment";
import { getRandomColor, createImageFromInitials } from "../Utils";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import "../Meeting.css";
import {
  getFunctionalGoalReviewMeetingData,
  postFunctionalGoalReviewMeetingData,
} from "../../store/actions";
import QuillEditor from "../../utils/QuillEditor";
import Alert from "../Alert";

const FunctionalGoalMeeting = (props) => {
  const { onClose } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [meetingId, setMeetingId] = useState(false);
  const [funReviewMeetingId, setFunReviewMeetingId] = useState(false);
  const [meetingName, setMeetingName] = useState("New Functional Goal Meeting");
  const [isOldMeeting, setIsOldMeeting] = useState(props.oldMeeting);

  const [owner, setOwner] = useState("");
  const [meetingDate, setMeetingDate] = useState("");
  const [meetingTime, setMeetingTime] = useState("");
  const [shareWithEveryOne, setShareWithEveryOne] = useState(true);
  const [empData, setEmpData] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [agenda, setAgenda] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState();
  const [ownerIcon, setOwnerIcon] = useState("");
  const [navigateReview, setNavigateReview] = useState(false);
  const [notesId, setNotesId] = useState("");
  const [goalReviewId, setGoalReviewId] = useState(false);
  const [repeatThisEvent, setRepeatThisEvent] = useState(false);
  const [meetingRepeatOn, setMeetingRepeatOn] = useState(null);
  const [meetingWeeklyOn, setMeetingWeeklyOn] = useState(null);
  const [showRepeatOption, setShowRepeatOption] = useState(false);
  const [repeatTillDate, setRepeatTillDate] = useState(null);
  const [fetchResponse, setFetchResponse] = useState(false);
  const [meetings, setMeetings] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState();
  const [allDay, setAllDay] = useState(false);
  const [isShared, setIsShared] = useState(false);
  const [isDisableGotoMeeting, setIsDisableGotoMeeting] = useState(false);
  const repeatData = [
    { value: "Daily", label: "Daily" },
    { value: "Weekly", label: "Weekly" },
    { value: "Monthly", label: "Monthly" },
    { value: "Yearly", label: "Yearly" },
  ];
  const WeeklyData = [
    { value: "monday", label: "Monday" },
    { value: "tuesday", label: "Tuesday" },
    { value: "wednesday", label: "Wednesday" },
    { value: "thursday", label: "Thursday" },
    { value: "friday", label: "Friday" },
    { value: "saturday", label: "Saturday" },
    { value: "sunday", label: "Sunday" },
  ];
  const [selectedRow, setSelectedRow] = useState(null);
  const isMeetingPostFetched = useSelector(
    (state) => state.isMeetingPostFetched
  );

  const postedMeetingData = useSelector((state) => state.postedMeetingData);
  const fecthPostFunGoalMeetingReviewResponse = useSelector(
    (state) => state.fecthPostFunGoalMeetingReviewResponse
  );
  const fetchFunGoalMeetingReviewData = useSelector(
    (state) => state.fetchFunGoalMeetingReviewData
  );

  const customStylesRepeatOption = {
    control: (provided) => ({
      ...provided,
      width: "82%",
    }),
    menu: (provided) => ({
      ...provided,
      width: "82%",
      zIndex: 999,
    }),
    option: (provided) => ({
      ...provided,
      width: "100%",
    }),
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "200px",
    }),
    menu: (provided) => ({
      ...provided,
      width: "200px",
    }),
    option: (provided) => ({
      ...provided,
      width: "200px",
      zIndex: 999,
    }),
  };
  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };
  const handleMeetingWeeklyOnChange = (selectedOption) => {
    setMeetingWeeklyOn(selectedOption);
  };
  useEffect(() => {
    if (!repeatThisEvent) {
      setMeetingRepeatOn("");
      setRepeatTillDate("");
      setShowRepeatOption(false);
      setMeetingWeeklyOn(null);
    }
  }, [repeatThisEvent]);
  useEffect(() => {
    if (fecthPostFunGoalMeetingReviewResponse && fetchResponse) {
      setFetchResponse(false);
      localStorage.setItem(
        "goal_review_id",
        fecthPostFunGoalMeetingReviewResponse.goal_review_id
      );
      localStorage.setItem(
        "note_id",
        fecthPostFunGoalMeetingReviewResponse.note_id
      );
      if (navigateReview) {
        navigate("/bireview");
      }
    }
    dispatch(
      getFunctionalGoalReviewMeetingData(
        localStorage.getItem("functional_goal_id"),
        localStorage.getItem("bi_id")
      )
    );
  }, [fecthPostFunGoalMeetingReviewResponse]);
  // useEffect(() => {
  //   if (navigateReview) {
  //     navigate("/bireview");
  //     // onClose();
  //   } else {
  //     console.log("false");
  //   }
  // }, [navigateReview]);
  useEffect(() => {
    if (fetchFunGoalMeetingReviewData) {
      setMeetings(fetchFunGoalMeetingReviewData.review_dt);
      setEmpData(fetchFunGoalMeetingReviewData.emp_data);
      if (fetchFunGoalMeetingReviewData.emp_data.length > 0) {
        setEmpData(
          fetchFunGoalMeetingReviewData.emp_data.map((obj) => {
            return {
              value: obj.value,
              label: obj.label,
              icon: createImageFromInitials(
                30,
                obj.label,
                getRandomColor(obj.label)
              ),
            };
          })
        );
      }
      if (
        fetchFunGoalMeetingReviewData.review_dt?.length > 0 &&
        props.selectedSubMenuPermission === "read"
      ) {
        handleRowClick(fetchFunGoalMeetingReviewData.review_dt[0]);
        setIsDisableGotoMeeting(false);
      } else if (
        props.selectedSubMenuPermission === "read" &&
        fetchFunGoalMeetingReviewData.review_dt?.length <= 0
      ) {
        setIsDisableGotoMeeting(true);
        handleRowClick("");
      }
    }
  }, [fetchFunGoalMeetingReviewData]);
  useEffect(() => {
    if (showRepeatOption && meetingRepeatOn?.value === "Weekly") {
      if (selectedStartDate) {
        const dayName = new Date(selectedStartDate)
          .toLocaleDateString("en-US", { weekday: "long" })
          .toLowerCase();
        const matchedDay = WeeklyData.find((day) => day.value === dayName);
        if (matchedDay) {
          setMeetingWeeklyOn([matchedDay]);
        }
      }
    }
  }, [repeatThisEvent, showRepeatOption, meetingRepeatOn, selectedStartDate]);

  const handleAgendaOnChange = (content) => {
    setAgenda(content);
  };

  const handleCloseMeetingPopup = () => {
    onClose();
  };
  const handleShareWithEveryOneClick = () => {
    setShareWithEveryOne(!shareWithEveryOne);
  };
  useEffect(() => {
    const fullname = localStorage.getItem("full_name");
    if (fullname) {
      setOwner(fullname);
      setOwnerIcon(
        createImageFromInitials(30, fullname, getRandomColor(fullname))
      );
    }
  }, []);

  const handleEmployeeChange = (selectedOptions) => {
    const updatedOptions = selectedOptions.filter((option) =>
      empData.some((emp) => emp.value === option.value)
    );
    setSelectedEmployees(updatedOptions);
  };
  const handleRepeatThisEvent = () => {
    setRepeatThisEvent(!repeatThisEvent);
  };
  const handleMeetingRepeatOnChange = (selectedOption) => {
    setMeetingRepeatOn(selectedOption);
    setShowRepeatOption(selectedOption.value === "Weekly" ? true : false);
  };
  const handleRowClick = (row) => {
    const meetingDetails = row && row?.meeting_details[0];
    setIsShared(meetingDetails.is_shared);
    setNotesId(meetingDetails?.notes_id || "");
    setMeetingId(meetingDetails?.meeting_id || "");
    setGoalReviewId(meetingDetails?.goal_review_id || "");
    setOwner(meetingDetails?.owner || "");
    setOwnerIcon(
      meetingDetails?.owner
        ? createImageFromInitials(
            30,
            meetingDetails.owner,
            getRandomColor(meetingDetails.owner)
          )
        : null
    );
    setSelectedRow(meetingDetails?.goal_review_id || "");
    setMeetingName(meetingDetails?.meeting || "");
    setAgenda(meetingDetails?.agenda || "");
    setShareWithEveryOne(meetingDetails?.share_with_everyone || false);
    setMeetingDate(meetingDetails?.date || "");
    setMeetingTime(meetingDetails?.time || "");
    setAllDay(row.all_day || false);
    setRepeatThisEvent(meetingDetails?.repeat_this_event || false);
    setMeetingRepeatOn(meetingDetails?.repeat_on || "");

    if (meetingDetails?.repeat_on?.value === "Weekly") {
      setShowRepeatOption(true);
    } else {
      setShowRepeatOption(false);
    }

    setIsOldMeeting(true);
    setMeetingWeeklyOn(meetingDetails?.weekly_data || "");
    setRepeatTillDate(meetingDetails?.repeat_till || "");
    setFromDate(meetingDetails?.from_date || "");
    setToDate(meetingDetails?.to_date || "");

    const parsedStartDate = meetingDetails?.start
      ? moment(meetingDetails.start).toDate()
      : "";
    setSelectedStartDate(parsedStartDate);

    const parsedEndDate = meetingDetails?.end
      ? moment(meetingDetails.end).toDate()
      : "";
    setSelectedEndDate(parsedEndDate);
    if (!meetingDetails.is_shared) {
      const selectedEmps =
        empData.length > 0 && meetingDetails?.emp
          ? meetingDetails.emp.map((empId) => {
              const emp = empData.find((employee) => employee.value === empId);
              return emp;
            })
          : [];

      if (selectedEmps && selectedEmps.length > 0) {
        const selectedOptions = selectedEmps.map((employee) => ({
          value: employee.value,
          label: employee.label,
          icon: createImageFromInitials(
            30,
            employee.label,
            getRandomColor(employee.label)
          ),
        }));
        setSelectedEmployees(selectedOptions);
      } else {
        setSelectedEmployees([]);
      }
    }
    localStorage.setItem("goal_review_id", meetingDetails?.goal_review_id);
    localStorage.setItem("note_id", meetingDetails?.note_id);
  };
  const location = useLocation();
  const handleNotesClick = (navigateFlag) => {
    if (
      props.selectedSubMenuPermission === "admin" ||
      props.selectedSubMenuPermission === "write"
    ) {
      setNavigateReview(navigateFlag);
      let emp_list = [];
      if (!shareWithEveryOne) {
        emp_list = empData.map((emp) => ({
          key: emp.value,
          is_active: selectedEmployees.some(
            (selectedEmp) => selectedEmp.value === emp.value
          ),
        }));
      } else {
        emp_list = empData.map((emp) => ({
          key: emp.value,
          is_active: true,
        }));
      }

      if (meetingName !== "") {
        const formattedSelectedStartDate = moment(selectedStartDate).format(
          "YYYY-MM-DDTHH:mm:ss"
        );
        const formattedSelectedEndDate = selectedEndDate
          ? moment(selectedEndDate).format("YYYY-MM-DDTHH:mm:ss")
          : null;
        const meeting_weeklyon_list = WeeklyData.map((week) => ({
          key: week.value,
          is_active:
            meetingWeeklyOn?.length > 0 && meetingRepeatOn.value == "Weekly"
              ? meetingWeeklyOn.some(
                  (meeting_week) => meeting_week.value === week.value
                )
              : false,
        }));
        let post_meeting_data = {
          is_shared: isShared ? true : false,
          bi_id: localStorage.getItem("bi_id"),
          meeting: meetingName,
          agenda: agenda || "",
          meeting_id: meetingId,
          all_day: allDay || "",
          share_with_everyone: shareWithEveryOne,
          startdate: formattedSelectedStartDate,
          enddate: formattedSelectedEndDate,
          repeat_this_event: repeatThisEvent,
          meeting_repeat_on: meetingRepeatOn?.value || "",
          meeting_weekly_on: meeting_weeklyon_list,
          repeat_till: repeatTillDate,
          emp: emp_list,
          from_date: fromDate,
          to_date: toDate || "",
          goal_review_id: goalReviewId,
        };
        dispatch(
          postFunctionalGoalReviewMeetingData(
            localStorage.getItem("functional_goal_id"),
            post_meeting_data
          )
        );
        setFetchResponse(true);
      }
    } else if (props.selectedSubMenuPermission === "read") {
      navigate("/bireview");
      const currentUrl = location.pathname;
      if (currentUrl === "/bireview") {
        window.location.reload();
      }
    }
  };
  const handleIsAllDayClick = () => {
    setAllDay(!allDay);
    if (!allDay) {
      setSelectedEndDate("");
    }
  };
  const CustomInput = ({ value, onClick }) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        borderRadius: 8,
        border: "1px solid #ced4da",
        padding: "0px 8px",
        cursor: "pointer",
        width: "220px",
      }}
      onClick={onClick}
    >
      <input
        type="text"
        value={value}
        readOnly
        placeholder="yyyy-MM-dd HH:mm:ss"
        style={{
          flex: 1,
          border: "none",
          outline: "none",
          background: "transparent",
          margin: "5px 0",
        }}
      />
      <SlCalender />
    </div>
  );
  return (
    <div className="modal-overlay">
      <div
        className="modal-content meeting-popover"
        style={{
          padding: "20px",
          height: "95%",
          overflowX: "hidden",
          overflowY: "auto",
        }}
      >
        <div
          className="modal-header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h5
            style={{
              paddingTop: "5px",
              fontSize: "var(--main-heading)",
              fontWeight: "var(--heading-font-weight)",
            }}
          >
            {isOldMeeting
              ? "Functional Goal Meeting Planner"
              : "Functional Goal Meeting Planner - New Meeting"}
          </h5>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <AiOutlineClose
              size={24}
              onClick={handleCloseMeetingPopup}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        <hr style={{ borderTop: "1px solid" }} />
        <div className="modal-body" style={{ display: "flex" }}>
          <div
            className="modal-body-left"
            style={{
              width: "50%",
              border: "1px solid lightgray",
              background: "#ecf4f3",
              fontSize: "var(--sub-heading-font-size)",
            }}
          >
            <table
              className="meeting-table"
              style={{ width: "100%", textAlign: "center" }}
            >
              <thead
                style={{
                  backgroundColor: "#0A5F59",
                  color: "white",
                }}
              >
                <tr>
                  <th style={{ textAlign: "left", paddingLeft: "10px" }}>
                    Meeting
                  </th>
                  <th>Date and Time</th>
                  <th>Duration</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ height: "10px" }}></tr>
                {meetings?.length > 0 ? (
                  meetings.map((val, key) => (
                    <tr
                      key={key}
                      onClick={() => handleRowClick(val)}
                      style={{
                        cursor: "pointer",
                        border:
                          selectedRow === val.meeting_details[0]?.goal_review_id
                            ? "2px solid #0A5F59"
                            : "none",
                        backgroundColor:
                          selectedRow === val.meeting_details[0]?.goal_review_id
                            ? "#D9F6E4"
                            : "transparent",
                        color:
                          selectedRow === val.meeting_details[0]?.goal_review_id
                            ? "#0A5F59"
                            : "inherit",
                      }}
                    >
                      <td style={{ textAlign: "left", paddingLeft: "10px" }}>
                        {val.meeting_details[0]?.meeting}
                      </td>
                      <td>{val.meeting_details[0]?.start}</td>
                      <td>{val.meeting_details[0]?.duration}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={3}
                      style={{
                        height: "250px",
                        verticalAlign: "middle",
                        backgroundColor: "rgb(236, 244, 243)",
                      }}
                    >
                      No meetings available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div
            className="modal-body-right"
            style={{ width: "55%", paddingLeft: "20px" }}
          >
            <div className="row">
              <div className="col-12">
                <Form.Group
                  controlId="formMeetingName"
                  style={{
                    marginTop: 0,
                  }}
                >
                  <Form.Control
                    type="text"
                    value={meetingName}
                    onChange={(e) => setMeetingName(e.target.value)}
                    placeholder="Add Meeting Title"
                    required
                    disabled={
                      isShared || props.selectedSubMenuPermission === "read"
                    }
                  />
                </Form.Group>
              </div>
            </div>
            {/* <h6>{meetingName}</h6> */}
            <div className="row">
              <div className="col-6 col-lg-6 col-sm-12">
                <div
                  style={{
                    display: "flex",
                    marginBottom: "10px",
                  }}
                >
                  <b
                    style={{
                      paddingTop: "5px",
                      marginRight: "5px",
                      fontSize: "var(--sub-heading-font-size)",
                      fontWeight: "var(--heading-font-weight)",
                      textWrap: "nowrap",
                    }}
                  >
                    Starts On:
                  </b>
                  <div style={{ zIndex: 11 }}>
                    <DatePicker
                      placeholderText="yyyy-MM-dd HH:mm:ss"
                      selected={selectedStartDate}
                      onChange={handleStartDateChange}
                      showTimeSelect
                      timeFormat="HH:mm"
                      dateFormat="yyyy-MM-dd HH:mm:ss"
                      showYearDropdown
                      scrollableYearDropdown
                      customInput={<CustomInput />}
                      showMonthDropdown
                      className="custom-datepicker"
                      yearDropdownItemNumber={1000}
                      disabled={
                        isShared || props.selectedSubMenuPermission === "read"
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-6 col-sm-12">
                <div
                  style={{
                    display: "flex",
                    marginBottom: "10px",
                  }}
                >
                  <b
                    style={{
                      paddingTop: "5px",
                      marginRight: "5px",
                      fontSize: "var(--sub-heading-font-size)",
                      fontWeight: "var(--heading-font-weight)",
                      textWrap: "nowrap",
                    }}
                  >
                    Ends On:
                  </b>
                  <div style={{ zIndex: 11 }}>
                    <DatePicker
                      placeholderText="yyyy-MM-dd HH:mm:ss"
                      selected={selectedEndDate}
                      onChange={handleEndDateChange}
                      showTimeSelect
                      timeFormat="HH:mm"
                      dateFormat="yyyy-MM-dd HH:mm:ss"
                      showYearDropdown
                      scrollableYearDropdown
                      customInput={<CustomInput />}
                      showMonthDropdown
                      className="custom-datepicker"
                      yearDropdownItemNumber={1000}
                      disabled={
                        isShared || props.selectedSubMenuPermission === "read"
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-6 col-lg-6 col-sm-12">
                <div
                  style={{
                    // position: "relative",
                    // zIndex: 999,
                    display: "flex",
                  }}
                >
                  <b
                    style={{
                      paddingRight: "5px",
                      paddingTop: "5px",
                      fontSize: "var(--sub-heading-font-size)",
                      fontWeight: "var(--heading-font-weight)",
                    }}
                  >
                    Review&nbsp;Period:
                  </b>
                  <input
                    style={{ width: "200px" }}
                    type="date"
                    className="form-control"
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                    disabled={
                      isShared || props.selectedSubMenuPermission === "read"
                    }
                  />
                </div>
              </div>
              <div className="col-6 col-lg-6 col-sm-12">
                <div
                  style={{
                    // position: "relative",
                    // zIndex: 999,
                    display: "flex",
                  }}
                >
                  <b
                    style={{
                      paddingRight: "5px",
                      paddingTop: "5px",
                      paddingLeft: "5px",
                      fontSize: "var(--sub-heading-font-size)",
                      fontWeight: "var(--heading-font-weight)",
                    }}
                  >
                    To:
                  </b>
                  <input
                    style={{ width: "180px" }}
                    type="date"
                    className="form-control"
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                    disabled={
                      isShared || props.selectedSubMenuPermission === "read"
                    }
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-lg-5 col-sm-12">
                <div
                  // className="custom-datepicker"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: "var(--sub-heading-font-size)",
                      fontWeight: "var(--heading-font-weight)",
                      paddingRight: "5px",
                    }}
                  >
                    Owner:
                  </div>

                  <img alt="" src={ownerIcon} />
                  {owner}
                </div>
              </div>
            </div>
            <Form.Group controlId="formAdditionalField">
              <strong>
                <Form.Label
                  style={{
                    marginBottom: "0px",
                    fontSize: "var(--sub-heading-font-size)",
                    fontWeight: "var(--heading-font-weight)",
                  }}
                >
                  Agenda:
                </Form.Label>
              </strong>
              <div style={{ height: "380px" }}>
                <QuillEditor
                  value={agenda}
                  onChange={handleAgendaOnChange}
                  height={300}
                  disabled={
                    isShared || props.selectedSubMenuPermission === "read"
                  }
                />
              </div>
            </Form.Group>
            {!isShared && (
              <Form.Group
                controlId="formCheckbox"
                style={{ marginTop: "10px" }}
              >
                <Form.Check
                  type="checkbox"
                  label="Share with everyone"
                  checked={shareWithEveryOne}
                  onChange={() => handleShareWithEveryOneClick()}
                  disabled={
                    isShared || props.selectedSubMenuPermission === "read"
                  }
                />
              </Form.Group>
            )}
            {!shareWithEveryOne && !isShared && (
              <Form.Group>
                <span
                  data-toggle="popover"
                  data-trigger="focus"
                  data-content="Please selecet employee(s)"
                >
                  <Select
                    options={empData}
                    value={selectedEmployees}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions
                    getOptionLabel={(e) => (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img alt="" src={e.icon} />
                        <span style={{ marginLeft: 5 }}>{e.label}</span>
                      </div>
                    )}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    onChange={handleEmployeeChange}
                    allowSelectAll
                    isDisabled={
                      isShared || props.selectedSubMenuPermission === "read"
                    }
                  />
                </span>
              </Form.Group>
            )}
            <Form.Group
              controlId="formCheckboxallday"
              style={{ paddingTop: "10px" }}
            >
              <Form.Check
                type="checkbox"
                label="All Day"
                checked={allDay}
                onChange={() => handleIsAllDayClick()}
                disabled={
                  isShared || props.selectedSubMenuPermission === "read"
                }
              />
            </Form.Group>
            <Form.Group
              controlId="formCheckboxrepeatthisevent"
              style={{ paddingTop: "10px" }}
            >
              <Form.Check
                type="checkbox"
                label="Repeat this Event"
                checked={repeatThisEvent}
                onChange={() => handleRepeatThisEvent()}
                disabled={
                  isShared || props.selectedSubMenuPermission === "read"
                }
              />
            </Form.Group>
            {repeatThisEvent && (
              <>
                <div className="row">
                  <div className="col-6 col-lg-6 col-sm-12">
                    <Form.Group
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingTop: "10px",
                      }}
                    >
                      <strong>
                        <Form.Label
                          style={{
                            marginRight: "10px",
                            paddingTop: "5px",
                            textWrap: "nowrap",
                          }}
                        >
                          Repeat On:
                        </Form.Label>
                      </strong>
                      <Select
                        styles={customStyles}
                        options={repeatData}
                        value={meetingRepeatOn}
                        closeMenuOnSelect={true}
                        getOptionLabel={(e) => (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span style={{ marginLeft: 5 }}>{e.label}</span>
                          </div>
                        )}
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                        onChange={handleMeetingRepeatOnChange}
                        isMulti={false}
                        placeholder="Repeat On"
                        menuPlacement="top"
                        isDisabled={
                          isShared || props.selectedSubMenuPermission === "read"
                        }
                      />
                    </Form.Group>
                  </div>
                  <div className="col-6 col-lg-6 col-sm-12">
                    <Form.Group
                      style={{
                        paddingTop: "10px",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <strong>
                          <Form.Label
                            style={{
                              marginRight: "10px",
                              textWrap: "nowrap",
                            }}
                          >
                            Repeat Till:
                          </Form.Label>
                        </strong>
                        <div
                          style={{
                            minWidth: "200",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div style={{ zIndex: 11 }}>
                            <input
                              style={{ width: "200px" }}
                              type="date"
                              className="form-control"
                              value={repeatTillDate}
                              onChange={(e) =>
                                setRepeatTillDate(e.target.value)
                              }
                              disabled={
                                isShared ||
                                props.selectedSubMenuPermission === "read"
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <span style={{ fontSize: "var(--text-font-size)" }}>
                        *Leave blank to repeat always
                      </span>
                    </Form.Group>
                  </div>
                </div>
                {showRepeatOption && (
                  <Form.Group
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingTop: "10px",
                    }}
                  >
                    <strong>
                      <Form.Label
                        style={{
                          marginRight: "10px",
                          textWrap: "nowrap",
                        }}
                      >
                        Repeat Option:
                      </Form.Label>
                    </strong>
                    <div style={{ minWidth: "100%" }}>
                      <Select
                        styles={customStylesRepeatOption}
                        options={WeeklyData}
                        value={meetingWeeklyOn}
                        closeMenuOnSelect={true}
                        getOptionLabel={(e) => (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span style={{ marginLeft: 5 }}>{e.label}</span>
                          </div>
                        )}
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                        onChange={handleMeetingWeeklyOnChange}
                        isMulti={true}
                        placeholder="Repeat option"
                        menuPlacement="top"
                        isDisabled={
                          isShared || props.selectedSubMenuPermission === "read"
                        }
                      />
                    </div>
                  </Form.Group>
                )}
              </>
            )}
          </div>
        </div>
        <div
          className="modal-footer"
          style={{
            display: "flex",
            marginTop: 20,
            flexWrap: "wrap",
            justifyContent: "space-between",
            bottom: 20,
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
        >
          <button
            style={{
              width: "40%",
              backgroundColor:
                isShared || props.selectedSubMenuPermission === "read"
                  ? "#e9ecef"
                  : "#0A5F59",
              color:
                props.selectedSubMenuPermission === "read" ? "gray" : "white",
              borderRadius: 10,
              height: 48,
              border: "thin ridge",
              marginRight: 20,
              fontWeight: "var(--heading-font-weight)",
            }}
            onClick={(e) => handleNotesClick(false)}
            disabled={isShared || props.selectedSubMenuPermission === "read"}
          >
            Schedule Meeting
          </button>
          <button
            style={{
              width: "40%",
              backgroundColor: isDisableGotoMeeting ? "#e9ecef" : "#0A5F59",
              borderColor: "#0A5F59",
              color: isDisableGotoMeeting ? "gray" : "white",
              borderRadius: 10,
              height: 48,
              border: 0,
              fontWeight: "var(--heading-font-weight)",
            }}
            disabled={isDisableGotoMeeting}
            onClick={(e) => handleNotesClick(true)}
          >
            Go to meeting
            <FaArrowRight style={{ marginLeft: "5px" }} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default FunctionalGoalMeeting;
